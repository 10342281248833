import React from 'react'
import PropTypes from 'prop-types'

const IsCarted = ({ thisItemIsSelected, hubInfo }) => {
  return (
    <>
      {thisItemIsSelected ? (
        <div className="w-full text-center text-red-800 font-Catherina h-5 text-md mb-3">
          Item has been selected{' '}
          <span className="font-bold font-Proxima">{thisItemIsSelected.quantity}</span>{' '}
          {thisItemIsSelected.quantity === 1 ? <>time</> : <>times</>} on this{' '}
          {hubInfo.data.type}
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

IsCarted.propTypes = {
  thisItemIsSelected: PropTypes.any,
  hubInfo: PropTypes.any,
}

export default React.memo(IsCarted)
