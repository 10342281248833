import React from 'react'
import PropTypes from 'prop-types'
import ErrorImage from '../../images/SVG-Animation-404-Pag.gif'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <main className="h-screen bg-contain bg-restaurant-pattern">
            <article className="w-80 m-auto pt-20">
              <h3 className="text-black text-4xl font-extrabold capitalize text-center font-Catherina pb-9">
                oops!!!
              </h3>
              <img src={ErrorImage} alt="error" className="im" />
              <p className="text-center text-xl my-9 font-Proxima">something is wrong</p>
            </article>
          </main>
        </>
      )
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
}

export default ErrorBoundary
