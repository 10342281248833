import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { reset } from '../../redux/resources/resourceAction'
import PropTypes from 'prop-types'

const Header = ({ baseURL }) => {
  const navigate = useNavigate(),
    dispatch = useDispatch()
  return (
    <header className="w-screen bg-white min-h-10 pt-10 pb-3 ">
      <div className="w-container flex justify-between  m-auto">
        <div
          className=" w-1/6 text-2xl"
          onClick={() => {
            navigate(baseURL)
            dispatch(reset())
          }}
        >
          <i className="text-3xl fa-solid fa-arrow-left"></i>
        </div>
        <h2 className="capitalize w-1/6 text-center font-extrabold font-Catherina text-2xl">
          bill
        </h2>
        <div className=" w-1/6 text-2xl"></div>
      </div>
    </header>
  )
}

Header.propTypes = {
  baseURL: PropTypes.string,
}

export default Header
