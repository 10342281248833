import { React, useEffect, useState } from 'react'
import { Circles } from 'react-loader-spinner'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router'

const Loader = ({ bankTransferPage }) => {
  const [delay, setDelay] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      !bankTransferPage && setDelay(true)
    }, 35000)
  }, [])

  return (
    <>
      <div className="h-screen bg-contain  bg-restaurant-pattern">
        <main className="loading__">
          <Circles
            height="80"
            width="80"
            color="#000"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            visible={true}
          />
        </main>
      </div>
      {delay && (
        <div className="modal">
          <div className="overlay__black">
            <section className=" bg-white  w-3/5 m-auto mt-64 rounded-xl h-24 overflow-auto">
              <h2 className="text-center my-2 font-Proxima">Something went wrong</h2>

              <div className="flex mt-5 justify-center w-full">
                <button
                  className="h-7 w-20 bg-black font-Catherina text-white shadow-md rounded-md capitalize hover:bg-gray-300 hover:text-black hover:shadow-xl hover:border-solid hover:border-black"
                  onClick={() => {
                    navigate(0)
                    setDelay((prevState) => !prevState)
                  }}
                >
                  reload
                </button>
              </div>
            </section>
          </div>
        </div>
      )}
    </>
  )
}

Loader.propTypes = {
  bankTransferPage: PropTypes.any,
}

export default Loader
