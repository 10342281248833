import { useEffect } from 'react'
import { useSelector } from 'react-redux'

const useStoreData = () => {
  const { session, hubInfo, merchant } = useSelector((state) => state.resource)
  useEffect(() => {
    const isHubStored = JSON.parse(sessionStorage.getItem('Hub'))
    const isMerchantStored = JSON.parse(sessionStorage.getItem('Merchant'))
    if (session?.succeeded) {
      if (!isHubStored && hubInfo?.data) {
        sessionStorage.setItem('Hub', JSON.stringify(hubInfo))
      }
      if (!isMerchantStored && merchant?.data) {
        sessionStorage.setItem('Merchant', JSON.stringify(merchant))
      }
    }
  }, [session])
}

export default useStoreData
