import { React, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PaystackHookButton from '../PageTypeComponents/PaystackHook'
import { useSelector } from 'react-redux'
import ClearOutOrders from './ClearOutOrders'

import PropTypes from 'prop-types'

const Payment = ({ MyFee, Total }) => {
  const { merchant, splittedBill, billInfo, tipAmount, orderLength, order, baseURL } =
      useSelector((state) => state.resource),
    [modal, setModal] = useState(false),
    [unAttendedOrders, setUnAttentedOrderStatus] = useState(false),
    navigation = useNavigate(),
    confirmPayment = () => {
      navigation(`${baseURL}/pay-by-transfer`, {
        state: {
          total: Total,
          mySplit: splittedBill,
          myTotalSplit: MyFee,
        },
      })
    }
  useEffect(() => {
    if (order && order?.data && orderLength > 0) {
      setUnAttentedOrderStatus(true)
    }
  }, [])

  return (
    <>
      <button
        className="bg-black border-2 mb-2 border-black hover:bg-black tracking-wide text-white w-full text-lg font-Proxima font-bold py-2 px-4 rounded-lg capitalize"
        onClick={() => setModal(!modal)}
      >
        Pay bill
      </button>

      {modal && (
        <div className="modal">
          <div className="overlay__black" onClick={() => setModal(!modal)}></div>
          {unAttendedOrders ? (
            <ClearOutOrders
              modal={modal}
              setModal={setModal}
              unAttendedOrders={unAttendedOrders}
              setUnAttentedOrderStatus={setUnAttentedOrderStatus}
            />
          ) : (
            <section className="absolute top-55% bg-white  w-full rounded-t-3xl h-full overflow-auto">
              <div className="w-container m-auto">
                <div className="w-full h-10 justify-between flex mt-4">
                  <h1 className="font-Catherina capitalize text-3xl font-extrabold">
                    Pay bill
                  </h1>
                  <h2 className="font-Proxima capitalize text-3xl font-extrabold">
                    <span>
                      {merchant?.data?.currency ? merchant?.data?.currency.symbol : ''}
                    </span>
                    {splittedBill === 0
                      ? Math.ceil(
                          billInfo?.data.outstandingAmount + tipAmount
                        ).toLocaleString('en-US')
                      : (splittedBill + tipAmount).toLocaleString('en-US')}
                  </h2>
                </div>

                <div className="my-8">
                  <div
                    className="w-full h-12 rounded-xl bg-white flex justify-center border-spacing-1 border-2 border-black border-solid"
                    onClick={() => {
                      confirmPayment()
                    }}
                    id="bank"
                  >
                    <h3 className="text-black font-Proxima text-center text-xl font-bold mt-2 mr-3">
                      Pay with bank transfer
                    </h3>
                    <i className="fa-solid fa-arrow-right-arrow-left mt-3"></i>
                  </div>
                </div>

                <PaystackHookButton />
              </div>
            </section>
          )}
        </div>
      )}
    </>
  )
}
Payment.propTypes = {
  MyFee: PropTypes.number,
  Total: PropTypes.number,
}

export default Payment
