import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'

const useTrackInactivity = () => {
  const navigation = useNavigate(),
    params = useParams(),
    [lastTouchTime, setLastTouchTime] = useState(Date.now()),
    { baseURL } = useSelector((state) => state.resource),
    inactivityThreshold = Number(process.env.REACT_APP_INACTIVITY_THRESHOLD)

  let timer

  const checkInactivity = () => {
    const currentTime = Date.now()
    const elapsedTime = currentTime - lastTouchTime
    if (elapsedTime >= inactivityThreshold) {
      setTimeout(
        () =>
          navigation(baseURL ?? `/merchants/${params.merchantGuid}/${params.hubGuid}`),
        2000
      )
    }
  }

  useEffect(() => {
    const handleTouchEvent = () => {
      setLastTouchTime(Date.now())
      clearTimeout(timer)
      timer = setTimeout(checkInactivity, inactivityThreshold)
    }

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        checkInactivity()
      }
    }

    window.addEventListener('touchstart', handleTouchEvent)
    window.addEventListener('touchmove', handleTouchEvent)
    window.addEventListener('touchend', handleTouchEvent)
    window.addEventListener('keydown', handleTouchEvent)
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      window.removeEventListener('touchstart', handleTouchEvent)
      window.removeEventListener('touchmove', handleTouchEvent)
      window.removeEventListener('touchend', handleTouchEvent)
      window.removeEventListener('keydown', handleTouchEvent)
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      clearTimeout(timer)
    }
  }, [])
}

export default useTrackInactivity
