import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/PageTypeComponents/Loader'
import Error from '../components/Error/ErrorPage'
import { useNavigate } from 'react-router-dom'
import {
  MonnifyLogin,
  getBillFromStorage,
  reset,
} from '../redux/resources/resourceAction'
import Payment from '../components/modals/PaymentMode'
import SplitModal from '../components/modals/SplitBill'
import useFetchAPI from '../hooks/useFetchAPI'
import WaiterApproval from '../components/PageTypeComponents/WaiterApproval'
import useConsolidate from '../hooks/useConsolidateSession'
import usePermanentInformation from '../hooks/usePermanentInfomation'
import useGetTableBill from '../hooks/useGetTableBill'
import EmptyOrder from '../components/PageTypeComponents/EmptyOrder'
import useSetURL from '../hooks/useSetURL'
import useSessionExpired from '../hooks/useSessionExpired'
import useTrackInactivity from '../hooks/useTrackInactivity'
import TipSection from '../components/Bill/TipComponent'
import PurchasedItems from '../components/Bill/PurchasedItems'
import Receipts from '../components/Bill/Receipts'
import Header from '../components/Bill/Header'
import ChargeBreakdown from '../components/Bill/ChargesBreakdown'

const Bills = () => {
  const {
      order,
      orderApproved,
      isBillsError,
      isSessionLoading,
      isBillsLoading,
      billInfo,
      isOrderLoading,
      merchant,
      tipAmount,
      splittedBill,
      baseURL,
    } = useSelector((state) => state.resource),
    overall = 0,
    [style, SetStyle] = useState('relative -top-10 right-0 -py-10 pl-10 w-40'),
    myfee = splittedBill === 0 ? overall : splittedBill + tipAmount,
    bottomViewRef = useRef(null),
    dispatch = useDispatch(),
    navigate = useNavigate()

  usePermanentInformation()
  useFetchAPI()
  useGetTableBill({ billPage: true })
  useConsolidate()
  useSetURL()
  useSessionExpired()
  useTrackInactivity()

  useEffect(() => {
    if (!billInfo?.succeeded) {
      const Bill = JSON.parse(sessionStorage.getItem('Bill'))
      const SessionFromStorage = JSON.parse(sessionStorage.getItem('session'))
      if (Bill) {
        dispatch(
          getBillFromStorage({
            bill: { ...Bill, fromLocalStorage: true },
          })
        )
        if (!SessionFromStorage?.data) {
          setTimeout(() => {
            sessionStorage.removeItem('Bill')
            window.location.reload()
          }, 300000)
        }
      }
    }
  }, [billInfo])

  useEffect(() => {
    if (orderApproved) {
      navigate(baseURL)
    }
  }, [orderApproved])

  useEffect(() => {
    if (splittedBill > 0) {
      SetStyle('hidden')
    } else {
      SetStyle('relative -top-10 right-0 -py-10 pl-10 w-40')
    }
  }, [splittedBill])

  useEffect(() => {
    if (splittedBill) {
      var options = { behavior: 'smooth' }
      if (bottomViewRef.current) {
        bottomViewRef.current.scrollIntoView(options)
      }
    }
  }, [splittedBill])

  useEffect(() => {
    dispatch(reset())
  }, [])

  useEffect(() => {
    dispatch(MonnifyLogin())
  }, [])

  if (isBillsLoading || isSessionLoading || isOrderLoading) {
    return <Loader banktransferPage={false} />
  }
  if (isBillsError) {
    return <Error text={'The Bill might not be ready.'} status={true} />
  }

  if (billInfo?.fromLocalStorage) {
    return <Receipts style={style} />
  }
  if (billInfo?.succeeded && !billInfo?.fromLocalStorage) {
    if (billInfo?.data?.details?.length === 0 && order?.data?.details.length > 0) {
      return <WaiterApproval />
    }
    if (billInfo?.data?.details.length === 0) {
      return <EmptyOrder PageName={'bill'} />
    }
    return (
      <>
        <Header baseURL={baseURL} />
        <main className="w-container m-auto">
          <PurchasedItems billInfo={billInfo} merchant={merchant} />
          <ChargeBreakdown
            billInfo={billInfo}
            merchant={merchant}
            style={style}
            tipAmount={tipAmount}
            splittedBill={splittedBill}
            myfee={myfee}
          />
          {merchant.data.acceptOutsidePayments && <SplitModal />}
          <TipSection />
          {merchant.data.acceptOutsidePayments && (
            <Payment MyFee={myfee} Total={billInfo?.data.outstandingAmount} />
          )}
        </main>
        <footer ref={bottomViewRef}></footer>
      </>
    )
  }
  return <EmptyOrder PageName={'bill'} />
}
export default React.memo(Bills)
