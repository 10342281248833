import React from 'react'
import { useNavigate } from 'react-router'
import Lottie from 'lottie-react'
import waitingGIF from '../../images/13520-blue-bird-waiting.json'

const WaiterApproval = () => {
  const navigate = useNavigate()
  return (
    <>
      <header className="w-full h-40"></header>
      <main className="w-container m-auto h-60">
        <section className="w-full m-auto">
          <Lottie
            autoplay={true}
            mode="normal"
            loop={true}
            animationData={waitingGIF}
          ></Lottie>
        </section>
        <h2 className="font-Catherina text-lg text-center mt-2 mb-10">
          Come back after waiter approves your order
        </h2>
        <button
          className="bg-black hover:bg-black tracking-widest text-white w-full text-lg font-bold py-2 px-4 rounded-lg capitalize font-Catherina"
          onClick={() => navigate(-1)}
        >
          go back
        </button>
      </main>
    </>
  )
}

export default WaiterApproval
