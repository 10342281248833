import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { GetCategories, GetOrders, GetProducts } from '../redux/resources/resourceAction'
import useSession from './useSession'

const useFetchAPI = () => {
  const dispatch = useDispatch(),
    { category, products } = useSelector((state) => state.resource),
    params = useParams()

  useSession()
  useEffect(() => {
    if (!category?.data) {
      dispatch(
        GetCategories({
          Guid: params.merchantGuid,
        })
      )
    }
    dispatch(
      GetOrders({
        Guid: params.merchantGuid,
        hubGuid: params.hubGuid,
      })
    )
  }, [])

  useEffect(() => {
    if (category?.data && products.length === 0) {
      const ParentCategory = []
      const childCategory = []

      category.data.forEach((element) => {
        if (element.parent === null) {
          ParentCategory.push(element)
        } else {
          childCategory.push(element)
        }
      })

      if (ParentCategory.length !== 0) {
        ParentCategory.forEach((element) => {
          let childrenExist = childCategory.some(
            (childElement) => childElement?.parent?.guid === element?.guid
          )
          if (!childrenExist) {
            dispatch(
              GetProducts({
                Guid: params.merchantGuid,
                categoryGuid: element?.guid,
              })
            )
          }
        })
      }
      childCategory.forEach((element) => {
        dispatch(
          GetProducts({
            Guid: params.merchantGuid,
            categoryGuid: element?.guid,
          })
        )
      })
    }
  }, [category])
}

export default useFetchAPI
