import React from 'react'
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom'
import Home from './page/Home'
import './Styles/index.css'
import Bills from './page/Bills'
import Menu from './page/Menu'
import Final from './page/Final'
import ScrollToTop from './components/Scroll'
import Table from './page/Table'
import PayByTransfer from './page/PayByTransfer'
import MerchantHome from './page/MerchantHome'
import TransferDelay from './page/TransferDelay'
// fonts
import './fonts/Caterina.ttf'
import './fonts/ProximaNovaCond-Regular.ttf'
import './fonts/ProximaNovaCond-Bold.ttf'
import './fonts/MansalvaRegular.ttf'

import useRealTimeUpdates from './hooks/useRealTime'
import useStoreData from './hooks/useStoreData'
import NotFound from './components/Error/NoPageFound'

function App() {
  const params = useParams()
  useRealTimeUpdates(params.merchantGuid)
  useStoreData()

  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/merchants/:merchantGuid/:hubGuid" element={<MerchantHome />} />
          <Route
            path="/merchants/:merchantGuid/:hubGuid/:sessionGuid"
            element={<MerchantHome />}
          />

          <Route path="/merchants/:merchantGuid/:hubGuid/bill" element={<Bills />} />
          <Route
            path="/merchants/:merchantGuid/:hubGuid/:sessionGuid/bill"
            element={<Bills />}
          />

          <Route path="/merchants/:merchantGuid/:hubGuid/menu" element={<Menu />} />
          <Route
            path="/merchants/:merchantGuid/:hubGuid/:sessionGuid/menu"
            element={<Menu />}
          />

          <Route path="/merchants/:merchantGuid/:hubGuid/table" element={<Table />} />
          <Route
            path="/merchants/:merchantGuid/:hubGuid/:sessionGuid/table"
            element={<Table />}
          />

          <Route
            path="/merchants/:merchantGuid/:hubGuid/:sessionGuid/pay-by-transfer"
            element={<PayByTransfer />}
          />
          <Route
            path="/merchants/:merchantGuid/:hubGuid/pay-by-transfer"
            element={<PayByTransfer />}
          />

          <Route
            path="/merchants/:merchantGuid/:hubGuid/:sessionGuid/transfer-delay"
            element={<TransferDelay />}
          />
          <Route
            path="/merchants/:merchantGuid/:hubGuid/transfer-delay"
            element={<TransferDelay />}
          />

          <Route path="/merchants/:merchantGuid/:hubGuid/end" element={<Final />} />
          <Route
            path="/merchants/:merchantGuid/:hubGuid/:sessionGuid/end"
            element={<Final />}
          />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  )
}

export default App
