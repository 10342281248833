import { useNavigate } from 'react-router'
import EmptyImage from '../../images/food-delivery-icon-set-transportation-vector-32156989 1.png'
import PropTypes from 'prop-types'
import { Footer } from '../Footer'
import { useSelector } from 'react-redux'

const EmptyOrder = ({ PageName }) => {
  const navigate = useNavigate(),
    { baseURL } = useSelector((state) => state.resource)

  return (
    <>
      <header className="w-screen bg-restaurant-pattern min-h-10 pt-10 pb-3 ">
        <div className="w-container flex justify-between  m-auto">
          <i className="text-3xl fa-solid fa-arrow-left" onClick={() => navigate(-1)}></i>
          <h2 className="capitalize text-center font-extrabold font-Catherina text-2xl">
            {PageName}
          </h2>
          <div></div>
        </div>
      </header>
      <main className="bg-restaurant-pattern w-screen min-h-60">
        <section className="w-container m-auto">
          <div className="table__empty">
            <img src={EmptyImage} alt="Wine and Dine" className="w-50 m-auto pt-40" />
            <h2 className="font-Proxima text-center text-lg mt-10">
              View the menu to select items.
            </h2>
            <div className="w-full flex justify-center mt-5">
              <button
                className="bg-white border-2 mt-8 
                                        border-black hover:bg-white tracking-wide 
                                        text-black w-3/6 text-lg font-Proxima font-bold 
                                        py-2 px-4 rounded-lg capitalize"
                onClick={() => navigate(`${baseURL}/menu`)}
              >
                {' '}
                View menu
              </button>
            </div>
            <div className="w-full flex justify-center mt-3">
              <button
                className="bg-gray-300 border-2 mt-3 
                                        tracking-wide 
                                        text-black w-2/6 text-lg font-Proxima font-bold 
                                        py-1 px-3 rounded-lg capitalize"
                onClick={() => navigate(baseURL)}
              >
                home
              </button>
            </div>
          </div>
        </section>
      </main>
      <footer className="bg-restaurant-pattern w-screen min-h-10 pt-5">
        <Footer />
      </footer>
    </>
  )
}
EmptyOrder.propTypes = {
  PageName: PropTypes.string,
}

export default EmptyOrder
