import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useEffect } from 'react'
import { setBaseURL } from '../redux/resources/resourceAction'

const useSetURL = () => {
  const { session } = useSelector((state) => state.resource),
    params = useParams(),
    dispatch = useDispatch()

  useEffect(() => {
    if (!params.sessionGuid) {
      dispatch(
        setBaseURL({
          url: session?.data?.session?.guid
            ? `/merchants/${params.merchantGuid}/${params.hubGuid}/${session?.data?.session?.guid}`
            : `/merchants/${params.merchantGuid}/${params.hubGuid}`,
        })
      )
    } else {
      dispatch(
        setBaseURL({
          url: `/merchants/${params.merchantGuid}/${params.hubGuid}/${params.sessionGuid}`,
        })
      )
    }
  }, [session])
}

export default useSetURL
