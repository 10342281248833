import React from 'react'
import PropTypes from 'prop-types'

const MenuImage = ({ image, slug }) => {
  return (
    <>
      {image !== '/' ? (
        <div className="dish__image__holder">
          <img className="dish__image mt-2" src={image} alt={slug} />
        </div>
      ) : (
        <div className=" h-0 mt-7 w-full"></div>
      )}
    </>
  )
}

MenuImage.propTypes = {
  image: PropTypes.string,
  slug: PropTypes.string,
}

export default React.memo(MenuImage)
