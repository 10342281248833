import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Footer } from '../components/Footer'
import {
  DeleteOrderItems,
  GetHubInfo,
  GetMerchant,
  GetOrderInBackground,
  GetSession,
} from '../redux/resources/resourceAction'
import Loader from '../components/PageTypeComponents/Loader'
import Error from '../components/Error/ErrorPage'
import React, { useEffect, useState } from 'react'
import EditQuantityIcon from '../components/modals/EditQuantityModal'
import SuccessPopUP from '../components/PageTypeComponents/SuccessPopUp'
import usePermanentInformation from '../hooks/usePermanentInfomation'
import useSessionSettled from '../hooks/useSessionSettled'
import EmptyOrder from '../components/PageTypeComponents/EmptyOrder'
import useSetURL from '../hooks/useSetURL'
import useSessionExpired from '../hooks/useSessionExpired'
import useTrackInactivity from '../hooks/useTrackInactivity'

const Table = () => {
  const {
      orderApproved,
      isOrderLoading,
      isOrderError,
      isMerchantLoading,
      isMerchantSuccess,
      isMerchantError,
      isHubLoading,
      isHubSuccess,
      isHubError,
      order,
      hubInfo,
      merchant,
      session,
      orderLength,
      baseURL,
    } = useSelector((state) => state.resource),
    [sendToWaiter, setSendToWaiter] = useState(false),
    Dispatch = useDispatch(),
    navigate = useNavigate(),
    params = useParams(),
    deleteItem = (itemGuid, orderGuid) => {
      Dispatch(
        DeleteOrderItems({
          Guid: params.merchantGuid,
          orderGuid: orderGuid,
          itemGuid: itemGuid,
        })
      )
    }
  useSetURL()
  useSessionExpired()
  useTrackInactivity()
  usePermanentInformation()
  useEffect(() => {
    if (orderApproved) {
      navigate(baseURL)
    }
  }, [orderApproved])

  useEffect(() => {
    if (!isMerchantSuccess || !isHubSuccess) {
      Dispatch(
        GetMerchant({
          Guid: params.merchantGuid,
        })
      )
      Dispatch(
        GetHubInfo({
          Guid: params.merchantGuid,
          hubGuid: params.hubGuid,
        })
      )
    }
  }, [Dispatch, params.hubGuid, params.merchantGuid])

  useEffect(() => {
    Dispatch(
      GetOrderInBackground({
        Guid: params.merchantGuid,
        hubGuid: params.hubGuid,
      })
    )
    if (!session?.data) {
      Dispatch(
        GetSession({
          Guid: params.merchantGuid,
          hubGuid: params.hubGuid,
        })
      )
    }
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      Dispatch(
        GetOrderInBackground({
          Guid: params.merchantGuid,
          hubGuid: params.hubGuid,
        })
      )
    }, 45000)

    return () => clearInterval(interval)
  }, [])

  useSessionSettled()

  if (isOrderLoading || isMerchantLoading || isHubLoading) {
    return <Loader banktransferPage={false} />
  }

  if (
    (isOrderError || isMerchantError || isHubError) &&
    !isOrderLoading &&
    !isMerchantLoading &&
    !isHubLoading
  ) {
    return (
      <Error
        text={'There might be an issue with your connection. Kindly reload to continue.'}
        status={true}
      />
    )
  }

  if (!merchant || !merchant.succeeded) {
    return <Error text={'Merchant not found'} />
  }

  if (hubInfo.data === null) {
    return <Error text={'This Table  is not available'} status={false} />
  }

  if (!order?.data || !order) {
    return <EmptyOrder PageName={`${hubInfo.data.name} Order`} />
  }
  if (sendToWaiter) {
    return <SuccessPopUP url={baseURL} />
  }
  if (hubInfo?.data && order?.data && orderLength > 0) {
    return (
      <>
        <header className="w-screen bg-restaurant-pattern min-h-10 pt-10 pb-3 ">
          <div className="w-container flex justify-between  m-auto">
            <i
              className="text-3xl fa-solid fa-arrow-left"
              onClick={() => navigate(`${baseURL}/menu`)}
            ></i>
            <h2 className="capitalize text-center font-extrabold font-Catherina text-2xl">
              {hubInfo.data.name} Order
            </h2>
            <div></div>
          </div>
        </header>
        <main className="w-screen bg-restaurant-pattern min-h-75">
          <section className="w-container m-auto">
            <nav className=" w-100 flex">
              <h2 className="font-Proxima font-bold text-lg w-1/5 capitalize">qty</h2>
              <h2 className="font-Proxima capitalize font-bold w-3/5 text-start text-lg">
                item
              </h2>
              <h2 className="font-Proxima font-bold text-xl w-1/5"> </h2>
            </nav>
            <div className="min-h-45 w-full my-4">
              {order?.data.details.map((state) => (
                <div className="w-100 flex justify-between  min-h-2" key={state.guid}>
                  <div className=" flex justify-start w-1/5">
                    <h3 className="font-Proxima text-start font-normal ">
                      {state.quantity}
                    </h3>
                    <EditQuantityIcon
                      FoodName={state.merchantProduct.name}
                      quantity={state.quantity}
                      id={state.guid}
                    />
                  </div>
                  <h3 className="font-Proxima text-start uppercase font-normal w-3/5">
                    {state.merchantProduct.name}
                  </h3>
                  <h3
                    className="font-Proxima text-end font-normal w-1/5"
                    onClick={() => deleteItem(state.guid, order?.data.guid)}
                  >
                    <i className="fa-solid fa-trash-can icon__trash"></i>
                  </h3>
                </div>
              ))}
            </div>
          </section>
          <section className="w-container m-auto">
            <div className="w-full flex justify-between">
              <button
                className="bg-white w-45% border-2 border-black hover:bg-white tracking-wide text-black text-base h-10 font-Proxima font-bold  rounded-lg capitalize"
                onClick={() => navigate(`${baseURL}/menu`)}
              >
                change order
              </button>
              <button
                className="bg-black w-45% border-2 border-black 
                                hover:bg-black tracking-wide text-white text-base 
                                h-10 font-Proxima font-bold  rounded-lg capitalize"
                onClick={() => setSendToWaiter(!sendToWaiter)}
              >
                Send to waiter
              </button>
            </div>
          </section>
        </main>
        <footer className="bg-restaurant-pattern w-screen min-h-10 pt-5">
          <Footer />
        </footer>
      </>
    )
  }
  return <EmptyOrder PageName={`${hubInfo.data.name} Order`} />
}

export default React.memo(Table)
