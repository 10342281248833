import { React, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from '../components/PageTypeComponents/Loader'
import { GetReservedBankDetails, GetTimer } from '../redux/resources/resourceAction'
import Error from '../components/Error/ErrorPage'
import usePayByTransfer from '../hooks/usePayByTransfer'
import useSetURL from '../hooks/useSetURL'

const PayByTransfer = () => {
  const navigate = useNavigate(),
    dispatch = useDispatch(),
    {
      session,
      transactionStatus,
      splittedBill,
      tipAmount,
      merchant,
      isMerchantLoading,
      isBankDetailsLoading,
      isBankDetailsError,
      accountTimer,
      BankDetails,
      bankMessage,
      billInfo,
      isTimerLoading,
      monnifySuccess,
      monnifyAuthorization,
      baseURL,
    } = useSelector((state) => state.resource),
    params = useParams(),
    [delay, setDelay] = useState(accountTimer),
    minutes = Math.floor(delay / 60),
    seconds = Math.floor(delay % 60),
    [copied, setCopied] = useState(false),
    [visible, setVisiblity] = useState(''),
    copy = async (number) => {
      await navigator.clipboard.writeText(number)
      setCopied(!copied)
    },
    confirmPayment = () => {
      navigate(`${baseURL}/transfer-delay`)
    }
  useSetURL()
  usePayByTransfer()
  useEffect(() => {
    if (transactionStatus.reference === BankDetails?.responseBody?.transactionReference) {
      navigate(`${baseURL}/transfer-delay`)
    }
  }, [transactionStatus])

  useEffect(() => {
    if (accountTimer > 0) {
      setDelay(accountTimer)
    }
  }, [accountTimer])

  useEffect(() => {
    const timer = setInterval(() => {
      setDelay(delay - 1)
    }, 1000)

    if (delay === 0) {
      clearInterval(timer)
    }

    return () => {
      clearInterval(timer)
    }
  }, [delay])

  useEffect(() => {
    const handleVisibilityChange = () => {
      setVisiblity(document.visibilityState)
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  useEffect(() => {
    const monnifyReference = JSON.parse(sessionStorage.getItem('MNFY_DETAILS'))
    if (visible === 'visible') {
      dispatch(
        GetTimer({
          monnifyReference,
          monnifyAuthorization,
        })
      )
    }
  }, [visible])

  useEffect(() => {
    const monnifyReference = JSON.parse(sessionStorage.getItem('MNFY_DETAILS'))
    if (monnifyReference) {
      dispatch(
        GetReservedBankDetails({
          monnifyReference,
          monnifyAuthorization,
        })
      )
    } else {
      dispatch(
        GetReservedBankDetails({
          amount: Math.ceil(
            splittedBill > 0
              ? splittedBill + tipAmount
              : billInfo?.data?.outstandingAmount + tipAmount
          ),
          hub: params.hubGuid,
          sessionGuid: params.sessionGuid ?? session?.data?.session?.guid,
          currencyCode: merchant?.data?.currency?.isoCode,
          tips: tipAmount,
          breakDownAmount: Math.ceil(
            splittedBill > 0 ? splittedBill : billInfo?.data?.outstandingAmount
          ),
          merchantName: merchant?.data?.name,
          monnifyAuthorization,
        })
      )
    }
  }, [])

  useEffect(() => {
    const timeOut = setTimeout(() => {
      copied && setCopied(false)
    }, 3000)

    return () => clearTimeout(timeOut)
  }, [copied])

  useEffect(() => {
    if (bankMessage === 'Transaction already completed') {
      confirmPayment()
    }
  }, [bankMessage])

  if (isBankDetailsLoading || isMerchantLoading) {
    return <Loader banktransferPage={true} />
  }
  if (monnifySuccess === 'REJECTED') {
    return (
      <Error
        text={
          'Bank transfer is currently not available, please use another payment method'
        }
        goBack={true}
      />
    )
  }
  if (isBankDetailsError) {
    return (
      <Error
        text={'There seem to be a problem,'}
        secondText={'please try again.'}
        goBack={true}
      />
    )
  }

  if (BankDetails?.requestSuccessful && merchant.succeeded) {
    return (
      <>
        <header className="w-screen bg-white min-h-10 pt-10 pb-3 ">
          <div className="w-container flex justify-between  m-auto">
            <i
              className="text-3xl fa-solid fa-arrow-left"
              onClick={() => navigate(-1)}
            ></i>
            <h2 className="capitalize text-center font-extrabold font-Catherina text-2xl">
              pay by transfer
            </h2>
            <div></div>
          </div>
        </header>
        <main className="w-screen bg-white min-h-30">
          <section className="w-container m-auto">
            <h3 className="text-center font-Proxima text-lg mb-1">
              Transfer EXACTLY{' '}
              <span className="font-bold">
                {merchant?.data?.currency.symbol}
                {BankDetails?.responseBody.amount.toLocaleString('en-US')}
              </span>{' '}
              to the account below.
            </h3>
            <h4 className="text-center font-Proxima text-lg capitalize">
              account details
            </h4>
            <div
              className="w-2/3 h-16 bg-neutral-300 rounded-lg mb-2 mt-1 m-auto px-6"
              onClick={() => copy(BankDetails?.responseBody.accountNumber)}
            >
              <h4 className="font-Proxima text-center text-2xl py-1 w-full font-bold  tracking-widest">
                {BankDetails?.responseBody.accountNumber}
              </h4>
              {copied ? (
                <div className="w-full">
                  <h5 className="text-center font-Proxima">Copied !!</h5>
                </div>
              ) : (
                <div className="w-full flex justify-center">
                  <h5 className="text-center font-Proxima mr-3">Tap to copy </h5>
                  <i className="fa-regular fa-copy"></i>
                </div>
              )}
            </div>
            <div className="w-container m-auto my-3">
              <h4 className="text-center font-Proxima text-lg capitalize">
                account Name
              </h4>
              <h3 className="text-center font-Proxima text-2xl font-bold capitalize">
                {BankDetails?.responseBody.accountName}
              </h3>
            </div>
            <div className="w-container m-auto my-3">
              <h4 className="text-center font-Proxima text-lg capitalize">bank</h4>
              <h3 className="text-center font-Proxima text-2xl font-bold capitalize">
                {BankDetails?.responseBody.bankName}
              </h3>
            </div>
            <div className="w-container m-auto">
              <div className="transfer__expires--timer my-5 w-full">
                <h4 className="text-center font-Proxima text-lg capitalize">
                  Account expires in:
                </h4>
                {isTimerLoading ? (
                  <div className="animate-pulse w-full flex justify-center">
                    <div className="h-6 w-20 bg-slate-500 rounded"></div>
                  </div>
                ) : (
                  <h3 className="text-center font-Proxima text-2xl font-bold">
                    {minutes}m : {seconds}s
                  </h3>
                )}
              </div>
              <p className="text-center font-Proxima text-lg capitalize">
                NB: Please do not save this account number. <br />
                This account is for a <span className="font-bold">one time use</span>.
              </p>
            </div>
          </section>
          <section className="w-container h-28 my-3 m-auto flex justify-center ">
            <button
              className="bg-black border-2 mt-1 border-black
                                        hover:bg-black tracking-wide
                                        text-white w-full md:w-full 
                                        lg:w-96 text-lg font-Proxima 
                                        font-bold px-4 rounded-lg h-12
                                        capitalize"
              onClick={confirmPayment}
            >
              I&apos;ve transfered the money
            </button>
          </section>
        </main>
      </>
    )
  } else {
    return <Error text="An error has occured" status={true} />
  }
}

export default PayByTransfer
