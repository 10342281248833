import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { GetMerchant, GetSession } from '../redux/resources/resourceAction'

const usePayByTransfer = () => {
  const dispatch = useDispatch(),
    { isMerchantSuccess, isSessionSuccess } = useSelector((state) => state.resource),
    params = useParams()

  useEffect(() => {
    if (!isMerchantSuccess) {
      dispatch(
        GetMerchant({
          Guid: params.merchantGuid,
        })
      )
    }
  }, [])
  useEffect(() => {
    if (!isSessionSuccess) {
      dispatch(
        GetSession({
          Guid: params.merchantGuid,
          hubGuid: params.hubGuid,
        })
      )
    }
  }, [])
}

export default usePayByTransfer
