import React from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { CloseOutOrders } from '../../redux/resources/resourceAction'
import PropTypes from 'prop-types'

const ClearOutOrders = ({
  modal,
  setModal,
  unAttendedOrders,
  setUnAttentedOrderStatus,
}) => {
  const params = useParams(),
    dispatch = useDispatch()

  function ClearOrders() {
    dispatch(
      CloseOutOrders({
        merchantGuid: params.merchantGuid,
        sessionGuid: params.sessionGuid,
      })
    )
    setUnAttentedOrderStatus(!unAttendedOrders)
  }

  return (
    <>
      <section className="relative top-73% bg-white  w-full rounded-t-3xl h-full overflow-auto">
        <div className="w-container m-auto">
          <div className="h-3 w-full"></div>
          <h3 className="text-center font-Catherina mt-5 text-lg">
            There are recently added items yet to be sent to the waiter
          </h3>

          <div className="w-full justify-evenly flex mt-4">
            <button
              className="h-8 w-36 bg-black shadow-sm rounded-lg text-white capitalize"
              onClick={() => setModal(!modal)}
            >
              cancel
            </button>
            <button
              className="h-8 w-36 bg-black shadow-sm rounded-lg text-white capitalize"
              onClick={ClearOrders}
            >
              proceed to pay
            </button>
          </div>
        </div>
      </section>
    </>
  )
}

ClearOutOrders.propTypes = {
  modal: PropTypes.any,
  setModal: PropTypes.any,
  unAttendedOrders: PropTypes.any,
  setUnAttentedOrderStatus: PropTypes.any,
  bankTransferPage: PropTypes.any,
}

export default ClearOutOrders
