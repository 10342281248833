import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Footer } from '../components/Footer'
import Loader from '../components/PageTypeComponents/Loader'
import Error from '../components/Error/ErrorPage'
import { useNavigate, useParams } from 'react-router-dom'
import useConsolidate from '../hooks/useConsolidateSession'
import {
  GetTableBill,
  getBillFromStorage,
  onOrderApproval,
  updateTransactionStatus,
} from '../redux/resources/resourceAction'
import { Circles } from 'react-loader-spinner'
import usePermanentInformation from '../hooks/usePermanentInfomation'
import useSessionSettled from '../hooks/useSessionSettled'
import useFetchAPI from '../hooks/useFetchAPI'
import useGetTableBill from '../hooks/useGetTableBill'
import useSetURL from '../hooks/useSetURL'
import useSessionExpired from '../hooks/useSessionExpired'
import HeaderImage from '../components/MerchantHome/Header'
import HomePageErrorModal from '../components/MerchantHome/HomePageErrorModal'

const MerchantHome = () => {
  const {
      orderApproved,
      billInfo,
      isHubError,
      isHubLoading,
      isMerchantLoading,
      isMerchantError,
      isBillsLoading,
      isBillsError,
      merchant,
      session,
      hubInfo,
      transactionStatus,
      baseURL,
    } = useSelector((state) => state.resource),
    params = useParams(),
    dispatch = useDispatch(),
    navigation = useNavigate(),
    [delayedLoading, setDelayedLoadingStatus] = useState(false)

  usePermanentInformation()
  useFetchAPI()
  useGetTableBill({ billPage: false })
  useConsolidate()
  useSetURL()
  useSessionExpired()
  useEffect(() => {
    if (transactionStatus.isSuccessful && session?.data) {
      dispatch(
        GetTableBill({
          merchantGuid: params.merchantGuid,
          sessionGuid: params.sessionGuid ?? session?.data?.session?.guid,
        })
      )
      dispatch(
        updateTransactionStatus({
          isSuccessful: false,
          reference: '',
          merchantCheck: '',
        })
      )
    }
  }, [transactionStatus.isSuccessful])

  useEffect(() => {
    const interval = setInterval(() => {
      if (session?.data && !billInfo?.fromLocalStorage) {
        dispatch(
          GetTableBill({
            merchantGuid: params.merchantGuid,
            sessionGuid: params.sessionGuid ?? session?.data?.session?.guid,
          })
        )
      }
    }, 40000)

    return () => clearInterval(interval)
  }, [session, billInfo])

  useEffect(() => {
    if (orderApproved && session?.data) {
      dispatch(
        GetTableBill({
          merchantGuid: params.merchantGuid,
          sessionGuid: params.sessionGuid ?? session?.data?.session?.guid,
        })
      )
      dispatch(
        onOrderApproval({
          status: false,
        })
      )
    }
  }, [orderApproved, session])

  useEffect(() => {
    if (!session.succeeded || !session.data) {
      const Bill = JSON.parse(sessionStorage.getItem('Bill'))
      const SessionFromStorage = JSON.parse(sessionStorage.getItem('session'))
      if (Bill) {
        dispatch(
          getBillFromStorage({
            bill: { ...Bill, fromLocalStorage: true },
          })
        )
        if (!SessionFromStorage?.data) {
          setTimeout(() => {
            sessionStorage.removeItem('Bill')
            window.location.reload()
          }, 300000)
        }
      }
    }
  }, [])

  useSessionSettled()

  useEffect(() => {
    const timer = setTimeout(() => {
      setDelayedLoadingStatus(isBillsLoading)
    }, 10000)

    return () => clearTimeout(timer)
  }, [isBillsLoading])

  if (isMerchantLoading || isHubLoading) {
    return <Loader banktransferPage={false} />
  }

  if ((isMerchantError || isHubError) && !isMerchantLoading && !isHubLoading) {
    return (
      <Error
        text={'There might be an issue with your connection. Kindly reload to continue.'}
        status={true}
      />
    )
  }
  if (!merchant || !merchant.succeeded || merchant?.data === null) {
    return <Error text={'Merchant not found'} />
  }

  if (!hubInfo || !hubInfo.succeeded || hubInfo?.data === null) {
    return <Error text={'Table not found'} status={false} />
  }
  return (
    <>
      <HeaderImage merchant={merchant} />
      <main className="min-h-43 w-container m-auto -mt-16 lg:mt-1 md:mt-0 bg-white grow">
        <h1 className="text-center capitalize font-Catherina text-4xl min-h-1 font-extrabold tracking-wide mb-1 w-11/12 m-auto">
          {merchant?.data?.name}
        </h1>
        <p className="text-center font-Proxima text-1xl font-thin">
          {merchant?.data?.catchPhrase}
        </p>
        <h3 className="text-center font-Catherina italic capitalize text-2xl my-1">
          {hubInfo?.data?.name}
        </h3>
        <article className="w-full">
          <button
            className="bg-white border-2 mt-2 border-black tracking-wide text-black w-full text-lg font-Proxima font-bold h-10 rounded-lg capitalize"
            onClick={() => {
              navigation(`${baseURL}/menu`)
            }}
          >
            View menu
          </button>

          <div className="flex justify-between mt-10 ">
            <h2 className="font-Proxima text-2xl font-bold">Table bill</h2>

            <h2 className="font-Proxima text-2xl font-bold">
              {isBillsLoading ? (
                <Circles
                  height="18"
                  width="18"
                  color="#000"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperClass="l"
                  visible={true}
                />
              ) : (
                <>
                  {!isBillsError &&
                    (billInfo?.data ? (
                      !billInfo.fromLocalStorage ? (
                        <>
                          {merchant?.data?.currency?.symbol}
                          {(billInfo?.data?.outstandingAmount > 0
                            ? Math.ceil(billInfo?.data?.outstandingAmount)
                            : 0
                          ).toLocaleString('en-US')}
                        </>
                      ) : (
                        <>{merchant?.data?.currency?.symbol}0</>
                      )
                    ) : (
                      <>{merchant?.data?.currency?.symbol}0</>
                    ))}
                </>
              )}
            </h2>
          </div>

          <button
            className="bg-black border-2 border-black tracking-wide text-white w-full text-lg font-Proxima font-bold h-10 rounded-lg capitalize"
            onClick={() => {
              navigation(`${baseURL}/bill`)
            }}
          >
            View bill
          </button>
        </article>
      </main>
      <Footer />
      {(isBillsError || delayedLoading) && <HomePageErrorModal />}
    </>
  )
}

export default React.memo(MerchantHome)
