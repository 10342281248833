import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { GetSession } from '../redux/resources/resourceAction'

const useSession = () => {
  const dispatch = useDispatch()
  const params = useParams()

  useEffect(() => {
    dispatch(
      GetSession({
        Guid: params.merchantGuid,
        hubGuid: params.hubGuid,
      })
    )
  }, [])
}

export default useSession
