import axios from 'axios'
const BASE_URL = process.env.REACT_APP_BASE_URL
const MONNIFY_LOGIN = process.env.REACT_APP_MONNIFY_LOGIN
const MONNIFY_CONTRACT_CODE = process.env.REACT_APP_MONNIFY_CONTRACT_CODE
const MONNIFY_SECRET_KEY = process.env.REACT_APP_MONNIFY_SECRET_KEY
const MONNIFY_API_KEY = process.env.REACT_APP_MONNIFY_API_KEY
const MONNIFY_GET_RESERVED_ACCOUNT = process.env.REACT_APP_MONNIFY_GET_RESERVED_ACCOUNT
const MONNIFY_INIT_TRANSACTION_API = process.env.REACT_APP_MONNIFY_INITIALIZE_TRANSACTION
const MONNIFY_TEST_TRANSACTION_STATUS = process.env.REACT_APP_MONNIFY_TEST_STATUS
const date = new Date()

const getCategories = async (merchantDetails) => {
  const subUrl = '/Categories'
  const response = await axios.get(`${BASE_URL}/${merchantDetails.Guid}${subUrl}`)
  return response.data
}

const getProductsPerCategory = async (merchantDetails) => {
  const subUrl = `/Products?CategoryGuids=${merchantDetails.categoryGuid}`
  const response = await axios.get(`${BASE_URL}/${merchantDetails.Guid}${subUrl}`)
  return response.data
}

const getMerchant = async (merchantDetails) => {
  const response = await axios.get(`${BASE_URL}/${merchantDetails.Guid}`)
  return response.data
}

const getOrders = async (merchantDetails) => {
  const response = await axios.get(
    `${BASE_URL}/${merchantDetails.Guid}/hubs/${merchantDetails.hubGuid}/orders`
  )
  return response.data
}

const getHappyHour = async (merchantDetails) => {
  const response = await axios.get(`${BASE_URL}/${merchantDetails.Guid}/happyhour/exist`)
  return response.data
}

const getHubInfo = async (merchantDetails) => {
  const response = await axios.get(
    `${BASE_URL}/${merchantDetails.Guid}/hubs/${merchantDetails.hubGuid}`
  )
  return response.data
}

const getSession = async (details) => {
  const response = await axios.get(
    `${BASE_URL}/${details.Guid}/hubs/${details.hubGuid}/session`
  )

  return response.data
}

const createOrder = async (merchantDetails) => {
  const data = {
    quantity: merchantDetails.quantity,
    productGuid: merchantDetails.productGuid,
    notes: '',
  }
  const response = await axios.post(
    `${BASE_URL}/${merchantDetails.Guid}/hubs/${merchantDetails.hubGuid}/orders`,
    data
  )
  return response.data
}
const addToOrder = async (merchantDetails) => {
  const data = {
    quantity: merchantDetails.quantity,
    productGuid: merchantDetails.productGuid,
    notes: '',
  }
  const response = await axios.post(
    `${BASE_URL}/${merchantDetails.Guid}/orders/${merchantDetails.orderGuid}`,
    data
  )
  return response.data
}

const updateOrder = async (merchantDetails) => {
  const data = {
    orderGuid: merchantDetails.orderGuid,
    orderDetailsGuid: merchantDetails.orderDetailsGuid,
    productGuid: merchantDetails.productGuid,
    quantity: merchantDetails.quantity,
    notes: '',
  }
  const response = await axios.put(
    `${BASE_URL}/${merchantDetails.Guid}/orders/${merchantDetails.orderGuid}`,
    data
  )

  return response.data
}

const getTableBill = async ({ merchantGuid, sessionGuid }) => {
  const response = await axios.get(
    `${BASE_URL}/${merchantGuid}/session/${sessionGuid}/bill`
  )

  return response.data
}

const deleteOrderItems = async (merchantDetails) => {
  const response = await axios.delete(
    `${BASE_URL}/${merchantDetails.Guid}/orders/${merchantDetails.orderGuid}/items/${merchantDetails.itemGuid}`
  )
  return response.data
}

const postTransaction = async (Details) => {
  const data = {
    sessionGuid: Details.sessionGuid,
    amount: Details.amount,
    serviceFee: Details.serviceFee,
    transactionDate: `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
      '0' + date.getDate()
    ).slice(-2)}T${String(date.getHours()).padStart(2, '0')}:${String(
      date.getMinutes()
    ).padStart(2, '0')}:${String(date.getSeconds()).padStart(
      2,
      '0'
    )}.${date.getMilliseconds()}Z`,
    reference: Details.reference,
    paymentProcessor: Details.paymentProcessor,
    paymentMethod: Details.paymentMethod,
    breakdown: {
      amount: Details.breakDownAmount,
      tips: Details.tips,
    },
  }
  const response = await axios.post(
    `${BASE_URL}/${Details.merchantGuid}/transaction`,
    data
  )
  return response.data
}

const initiateTransaction = async (details) => {
  let randomString = (Math.random() + 1).toString(36).substring(7)
  let loginResponse = details.loginResponse

  const AccountHeader = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${loginResponse.responseBody.accessToken}`,
  }
  const data = {
    amount: details.amount,
    customerName: `customer on ${details.hub}`,
    customerEmail: `${details.sessionGuid}@outside.com`,
    paymentReference: `${details.sessionGuid}__${date.getTime()}__${randomString}`,
    paymentDescription: `Payment made from hub ${details.hub} on ${details.merchantName}`,
    currencyCode: details.currencyCode,
    contractCode: MONNIFY_CONTRACT_CODE,
    redirectUrl: 'https://outside-app-stage.azurewebsites.net/',
    paymentMethods: ['ACCOUNT_TRANSFER'],
    metaData: {
      amount: details.breakDownAmount,
      tips: details.tips,
    },
  }
  const getOneTimeDetails = await axios.post(MONNIFY_INIT_TRANSACTION_API, data, {
    headers: AccountHeader,
  })

  if (isSessionStorageEnabled()) {
    sessionStorage.setItem(
      'MNFY_DETAILS',
      JSON.stringify(getOneTimeDetails.data.responseBody.transactionReference)
    )
  }

  return getOneTimeDetails.data
}

const checkTransactionStatusOnMonnify = async (details) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${details.loginResponse.responseBody.accessToken}`,
  }
  const response = await axios.get(
    `${MONNIFY_TEST_TRANSACTION_STATUS}${details.reference}`,
    { headers }
  )
  return response.data
}

const monnifyLogin = async () => {
  const loginData = {
    APIKey: MONNIFY_API_KEY,
    SecretKey: MONNIFY_SECRET_KEY,
  }

  const loginHeaders = {
    'Content-Type': 'application/json',
    Authorization: `Basic ${window.btoa(`${loginData.APIKey}:${loginData.SecretKey}`)}`,
  }
  const response = await axios.post(MONNIFY_LOGIN, loginData, { headers: loginHeaders })
  return response.data
}

const getReservedBankDetails = async (details) => {
  let loginResponse = details.monnifyAuthorization
    ? details.monnifyAuthorization
    : await monnifyLogin()

  let getDetails

  if (details.monnifyReference) {
    getDetails = details.monnifyReference
  } else {
    const newAccountReference = await initiateTransaction({ ...details, loginResponse })
    getDetails = newAccountReference.responseBody.transactionReference
  }
  const AccountHeader = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${loginResponse.responseBody.accessToken}`,
  }
  const data = {
    transactionReference: getDetails,
  }
  const response = await axios.post(MONNIFY_GET_RESERVED_ACCOUNT, data, {
    headers: AccountHeader,
  })
  return response.data
}

const postConsolidateSession = async (details) => {
  const response = await axios.post(
    `${BASE_URL}/${details.merchantGuid}/Sessions/${details.sessionGuid}/consolidate`
  )
  return response.data
}

const closeOutOrders = async (details) => {
  const response = await axios.delete(
    `${BASE_URL}/${details.merchantGuid}/sessions/${details.sessionGuid}/closeout`
  )
  return response.data
}

const checkTransactionStatusOnOutside = async (details) => {
  const response = await axios.get(
    `${BASE_URL}/${details.merchantGuid}/session/${details.sessionGuid}/Transaction?reference=${details.reference}`
  )
  return response.data
}

const isSessionStorageEnabled = () => {
  try {
    const key = `__storage__test`
    window.sessionStorage.setItem(key, null)
    window.sessionStorage.removeItem(key)
    return true
  } catch (e) {
    return false
  }
}

export const ResourceServices = {
  getCategories,
  getProductsPerCategory,
  getMerchant,
  getOrders,
  getHubInfo,
  addToOrder,
  createOrder,
  updateOrder,
  deleteOrderItems,
  getHappyHour,
  postTransaction,
  getReservedBankDetails,
  monnifyLogin,
  getTableBill,
  getSession,
  postConsolidateSession,
  checkTransactionStatusOnMonnify,
  closeOutOrders,
  checkTransactionStatusOnOutside,
}
