import { useNavigate } from 'react-router-dom'
import invoice from '../../images/alert.png'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCategory, setParentCategory } from '../../redux/resources/resourceAction'
import PropTypes from 'prop-types'

const MenuHeader = ({ animation }) => {
  const { category, parentCategory, selectedCategory, baseURL } = useSelector(
      (state) => state.resource
    ),
    [navBarDisplay, SetnavBarDisplay] = useState(0),
    [displayCategory, setDisplayCategory] = useState([]),
    [SelectedParentCategory, setSelectedParentCategory] = useState(),
    [displayParentCategory, setDisplayParentCategory] = useState([]),
    navigation = useNavigate(),
    scrollRef = useRef(null),
    scrollParentRef = useRef(null),
    SelectedCategoryRef = useRef(null),
    SelectedParentCategoryRef = useRef(null),
    dispatch = useDispatch(),
    [scrollWidth, setScrollWidth] = useState(0),
    [scrollParentWidth, setScrollParentWidth] = useState(0),
    ScrollButton = (direction) => {
      if (direction) {
        scrollRef.current.scrollLeft -= 250
      } else {
        scrollRef.current.scrollLeft += 250
      }
    },
    myFunction = (event) => {
      SetnavBarDisplay(event.currentTarget.scrollLeft)
    }

  const changeCategory = (category) => {
    dispatch(
      setCategory({
        category: category,
      })
    )
  }

  const changeParentCategory = (data) => {
    setSelectedParentCategory(data)
    dispatch(
      setParentCategory({
        parentCategory: data,
      })
    )
  }

  useEffect(() => {
    if (scrollRef.current !== null) {
      setScrollWidth(scrollRef.current.scrollWidth)
    }
  }, [scrollWidth])

  useEffect(() => {
    if (scrollParentRef.current !== null) {
      setScrollParentWidth(scrollParentRef.current.scrollWidth)
    }
  }, [scrollParentWidth])

  useEffect(() => {
    const AllCategoryParentIsNull = category.data.filter((state) => state.parent === null)
    if (AllCategoryParentIsNull.length === category.data.length) {
      setDisplayCategory(AllCategoryParentIsNull)
      setDisplayParentCategory([])
      dispatch(
        setCategory({
          category: category.data[0]?.guid,
        })
      )
    } else {
      changeParentCategory(category.data.filter((state) => state.parent === null)[0].guid)
      setDisplayParentCategory(category.data.filter((state) => state.parent === null))
      setDisplayCategory(
        category.data
          .filter((state) => state.parent !== null)
          .filter((state) => state.parent?.guid === parentCategory)
      )
    }
  }, [])

  useEffect(() => {
    if (parentCategory) {
      setDisplayCategory(
        category.data
          .filter((state) => state.parent !== null)
          .filter((state) => state.parent.guid === parentCategory)
      )
    }
  }, [category.data, parentCategory])

  useEffect(() => {
    if (displayParentCategory.length > 0) {
      changeCategory(displayCategory[0]?.guid ?? parentCategory)
    }
  }, [displayCategory, displayParentCategory])

  useEffect(() => {
    if (scrollRef.current && SelectedCategoryRef.current) {
      const container = scrollRef.current
      const element = SelectedCategoryRef.current
      const positionLeft = element.getBoundingClientRect()
      const containerLeft = container.getBoundingClientRect()
      container.scrollTo({
        left: positionLeft.left - containerLeft.left + container.scrollLeft,
        behavior: 'smooth',
      })
    }
  }, [selectedCategory])

  useEffect(() => {
    if (scrollParentRef.current && SelectedParentCategoryRef.current) {
      const container = scrollParentRef.current
      const element = SelectedParentCategoryRef.current
      const positionLeft = element.getBoundingClientRect()
      const containerLeft = container.getBoundingClientRect()
      container.scrollTo({
        left: positionLeft.left - containerLeft.left + container.scrollLeft,
        behavior: 'smooth',
      })
    }
  }, [SelectedParentCategory])

  return (
    <header className="fixed top-0 w-full min-h-11 bg-white fixed-navbar">
      <div className="w-container m-auto mt-5 ">
        <div className="flex m-auto justify-between ">
          <div
            className=" w-1/4 text-2xl"
            onClick={() => {
              navigation(baseURL)
            }}
          >
            <i className="text-3xl fa-solid fa-arrow-left"></i>
          </div>
          <h1 className="w-2/4 capitalize font-Catherina text-3xl text-center">menu</h1>
          <div
            className="w-1/4 flex justify-end"
            onClick={() => {
              navigation(`${baseURL}/table`)
            }}
          >
            {animation && (
              <span className="animate-ping relative inline-flex h-4 w-4 rounded-full bg-red-500 opacity-75 left-6"></span>
            )}
            <img className="h-7 pt-1" src={invoice} alt="alert_user"></img>
          </div>
        </div>

        <div className="flex w-container m-auto justify-center ">
          <div className="w-20 h-1px mt-1  rounded-xl bg-grey"></div>
          <div className="w-2 mx-4 h-2 rounded-xl bg-grey"></div>
          <div className="w-20 h-1px mt-1 rounded-xl bg-grey"></div>
        </div>

        <nav className=" mt-3 flex justify-center">
          <div
            className="flex min-w-0 overflow-x-auto whitespace-nowrap scrollbar-hide "
            ref={scrollParentRef}
          >
            {displayParentCategory.map((state) => (
              <div className="my-3" key={state.slug}>
                {parentCategory === state?.guid ? (
                  <button
                    key={state.slug}
                    ref={SelectedParentCategoryRef}
                    className="rounded-3xl
                                        bg-black   mx-2 border-2
                                        tracking-wide text-white 
                                        text-lg h-9 font-Proxima 
                                        font-bold min-w-6 px-5 capitalize"
                    onClick={() => changeParentCategory(state.guid)}
                  >
                    {state?.name}
                  </button>
                ) : (
                  <button
                    key={state?.slug}
                    className="rounded-3xl
                                        bg-neutral-300 mx-2 border-2
                                        tracking-wide  text-black 
                                        text-lg h-9 font-Proxima 
                                        font-bold min-w-6 px-5 capitalize"
                    onClick={() => changeParentCategory(state?.guid)}
                  >
                    {state?.name}
                  </button>
                )}
              </div>
            ))}
          </div>
        </nav>
        <nav className=" h-10 mt-3 flex justify-center">
          <>
            {navBarDisplay >= 40 && (
              <i
                className="fa-solid fa-chevron-left text-lg w-2.5 md:hidden lg:hidden xl:hidden mr-3"
                onClick={() => ScrollButton(true)}
              ></i>
            )}
            <ul
              className="flex min-w-0 overflow-x-auto whitespace-nowrap scrollbar-hide"
              ref={scrollRef}
              onScroll={myFunction}
            >
              {displayCategory.map((state) => (
                <li onClick={() => changeCategory(state.guid)} key={state.slug}>
                  {selectedCategory === state.guid ? (
                    <div
                      id={state.name}
                      className="style font-Proxima capitalize text-lg mx-4"
                      ref={SelectedCategoryRef}
                    >
                      {state.name}
                    </div>
                  ) : (
                    <div id={state.name} className="font-Proxima capitalize text-lg mx-4">
                      {state.name}
                    </div>
                  )}
                </li>
              ))}
            </ul>
            {navBarDisplay < scrollWidth - window.innerWidth &&
              displayCategory?.length > 3 && (
                <i
                  className="fa-solid w-2.5 fa-chevron-right icon__scroll--right text-lg  md:hidden lg:hidden xl:hidden"
                  onClick={() => ScrollButton(false)}
                ></i>
              )}
          </>
        </nav>
      </div>
    </header>
  )
}

MenuHeader.propTypes = {
  animation: PropTypes.any,
}

export default React.memo(MenuHeader)
