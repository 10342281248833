import PropTypes from 'prop-types'
import SuccessGIF from '../../images/successful-gif.gif'
import React from 'react'

const ButtonArea = ({ selectItem, selected }) => {
  return (
    <div className="w-container flex m-auto justify-around mt-1 mb-1 min-h-2">
      {!selected ? (
        <button
          className="bg-black border-2 border-black  tracking-wide text-white text-lg h-9 font-Proxima font-bold w-2/6 rounded-md capitalize"
          onClick={selectItem}
        >
          select
        </button>
      ) : (
        <img className="h-14" src={SuccessGIF} alt="final_logo_goodluck"></img>
      )}
    </div>
  )
}

ButtonArea.propTypes = {
  selectItem: PropTypes.any,
  selected: PropTypes.any,
}
export default React.memo(ButtonArea)
