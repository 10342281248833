import PropTypes from 'prop-types'
import React from 'react'

const PurchasedItems = ({ billInfo, merchant }) => {
  return (
    <section className="w-full m-auto  min-h-30 mb-2">
      <nav className=" w-100 mb-3 flex">
        <h2 className="font-Proxima capitalize font-bold text-base w-1/5">qty</h2>
        <h2 className="font-Proxima capitalize font-bold w-3/5 text-start text-base">
          item
        </h2>
        <h2 className="font-Proxima font-bold text-base text-end capitalize w-1/5">
          price({merchant?.data?.currency ? merchant?.data?.currency.symbol : ''})
        </h2>
      </nav>
      {billInfo?.data?.details?.map((state) => (
        <div className="w-100 flex justify-between text-base min-h-2" key={state.guid}>
          <h3 className="font-Proxima text-start font-normal w-1/5">{state.quantity}</h3>
          <h3 className="font-Proxima text-start uppercase font-normal w-3/5">
            {state.merchantProduct.name}
          </h3>
          <h3 className="font-Proxima text-end font-normal w-1/5">
            {new Intl.NumberFormat('en-US', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }).format(state.amount)}
          </h3>
        </div>
      ))}
    </section>
  )
}

PurchasedItems.propTypes = {
  billInfo: PropTypes.any,
  merchant: PropTypes.any,
}

export default React.memo(PurchasedItems)
