import React from 'react'
import { usePaystackPayment } from 'react-paystack'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { PostTransaction } from '../../redux/resources/resourceAction'

const PaystackHookButton = () => {
  const { tipAmount, splittedBill, session, billInfo, baseURL } = useSelector(
      (state) => state.resource
    ),
    config = {
      email: `${session?.data?.session?.guid}@${process.env.REACT_APP_PAYSTACK_EMAIL_SUFFIX}`,
      amount: +(
        Math.ceil(
          splittedBill
            ? splittedBill + tipAmount
            : tipAmount + billInfo?.data?.outstandingAmount
        ) * 100
      ).toFixed(2),
      publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
    },
    dispatch = useDispatch(),
    Params = useParams(),
    initializePayment = usePaystackPayment(config),
    navigate = useNavigate(),
    onSuccess = (data) => {
      navigate(`${baseURL}/end`)
      dispatch(
        PostTransaction({
          merchantGuid: Params.merchantGuid,
          serviceFee: billInfo?.data.serviceFee,
          sessionGuid: Params.sessionGuid ?? session?.data?.session?.guid,
          amount: Math.ceil(
            splittedBill > 0
              ? splittedBill + tipAmount
              : billInfo?.data.outstandingAmount + tipAmount
          ),
          reference: data.reference,
          paymentProcessor: 0,
          paymentMethod: 1,
          breakDownAmount: Math.ceil(
            splittedBill > 0 ? splittedBill : billInfo?.data.outstandingAmount
          ),
          tips: tipAmount,
        })
      )
    },
    onClose = () => {
      navigate(`${baseURL}/bill`)
    }

  return (
    <>
      <div
        className="w-full h-12 rounded-xl bg-white flex justify-center border-spacing-1 border-2 border-black border-solid"
        onClick={() => initializePayment(onSuccess, onClose)}
      >
        <h3 className="text-black font-Proxima text-center text-xl font-bold mt-2 mr-5">
          Pay with card
        </h3>
        <i className="fa-regular fa-credit-card mt-3"></i>
      </div>
    </>
  )
}

export default PaystackHookButton
