import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import PurchasedItems from './PurchasedItems'
import handdrawncircle from '../../images/handdrawn-cricle.png'
const Receipts = ({ style }) => {
  const { baseURL, billInfo, merchant } = useSelector((state) => state.resource),
    navigate = useNavigate()
  return (
    <>
      <header className="w-screen bg-white min-h-10 pt-10 pb-3 ">
        <div className="w-container flex justify-between  m-auto">
          <div
            className=" w-1/6 text-2xl"
            onClick={() => {
              navigate(baseURL)
            }}
          >
            <i className="text-3xl fa-solid fa-arrow-left"></i>
          </div>
          <h2 className="capitalize w-1/6 text-center font-extrabold font-Catherina text-2xl">
            bill
          </h2>
          <div className=" w-1/6 text-2xl"></div>
        </div>
      </header>
      <main className="w-container m-auto">
        <PurchasedItems billInfo={billInfo} merchant={merchant} />
        {billInfo?.data?.merchantAdminDiscount > 0 ? (
          <section className="w-full m-auto my-2 h-5 flex justify-between">
            <h2 className="font-normal capitalize font-Proxima ">discount</h2>
            <h2 className="font-normal capitalize font-Proxima text-right bg-center bg-no-repeat">
              - {'  '}
              {merchant?.data?.currency ? merchant?.data?.currency.symbol : ''}
              {new Intl.NumberFormat('en-US', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }).format(billInfo?.data?.merchantAdminDiscount)}
            </h2>
          </section>
        ) : (
          <></>
        )}
        <section className="w-full m-auto mb-3 flex justify-end">
          {billInfo?.data?.details?.length > 0 && (
            <div className="w-1/4 h-1.2px bg-black"></div>
          )}
        </section>
        <section className=" m-auto min-h-8 flex justify-end">
          <h2 className="font-normal capitalize font-Proxima ">
            {merchant?.data?.currency ? merchant?.data?.currency.symbol : ''}
            {billInfo?.data?.totalAmount.toLocaleString('en-US')}
          </h2>
        </section>
        <section className=" m-auto min-h-1">
          {billInfo?.data?.consumptionTax > 0 && (
            <div className="flex justify-between">
              <h2 className="font-normal capitalize font-Proxima">consumption Tax</h2>
              <h2 className="font-normal capitalize font-Proxima text-right bg-center bg-no-repeat">
                {merchant?.data?.currency ? merchant?.data?.currency.symbol : ''}
                {new Intl.NumberFormat('en-US', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }).format(billInfo?.data?.consumptionTax)}
              </h2>
            </div>
          )}
          {(billInfo?.data?.serviceFee > 0 ||
            billInfo?.data?.platformFee > 0 ||
            billInfo?.data?.platformServiceFee > 0) && (
            <div className="flex justify-between">
              <h2 className="font-normal capitalize font-Proxima">service fee</h2>
              <h2 className="font-normal capitalize font-Proxima  text-right bg-center bg-no-repeat ">
                {merchant?.data?.currency ? merchant?.data?.currency.symbol : ''}
                {new Intl.NumberFormat('en-US', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }).format(
                  billInfo?.data?.serviceFee +
                    billInfo?.data?.platformFee +
                    billInfo?.data?.platformServiceFee
                ) ?? 0.0}
              </h2>
            </div>
          )}
          {billInfo?.data?.tax > 0 && (
            <div className="flex justify-between">
              <h2 className="font-normal capitalize font-Proxima">vat</h2>
              <h2 className="font-normal capitalize  text-right font-Proxima bg-center bg-no-repeat">
                {merchant?.data?.currency ? merchant?.data?.currency.symbol : ''}
                {new Intl.NumberFormat('en-US', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }).format(billInfo?.data?.tax)}
              </h2>
            </div>
          )}

          {billInfo.data?.paidAmount > 0 ? (
            <div className="flex justify-between mt-4">
              <h2 className=" capitalize font-Proxima font-normal">Amount Paid</h2>
              <h2 className="font-normal capitalize  text-right font-Proxima bg-center bg-no-repeat">
                {merchant?.data?.currency ? merchant?.data?.currency.symbol : ''}
                {Math.ceil(billInfo?.data?.finalAmount).toLocaleString('en-US') ?? 0.0}
              </h2>
            </div>
          ) : (
            <></>
          )}
          <div className="flex mt-1 justify-between">
            <h2 className="text-2xl  capitalize font-extrabold pt-10 font-Proxima">
              Balance
            </h2>

            {style !== 'hidden' ? (
              <div className={'h-8 mt-20   grid grid-cols-1 content-end '}>
                <h2 className="text-2xl font-extrabold text-right font-Proxima">
                  {merchant?.data?.currency ? merchant?.data?.currency.symbol : ''}0
                </h2>
                <img className={style} src={handdrawncircle} alt={'handrawn circle'} />
              </div>
            ) : (
              <div className={'h-28 pt-10'}>
                <h2 className="text-2xl font-extrabold text-right font-Proxima bg-center bg-no-repeat bg-hidden-dash">
                  {merchant?.data?.currency ? merchant?.data?.currency.symbol : ''}
                  {Math.ceil(billInfo?.data.outstandingAmount).toLocaleString('en-US')}
                </h2>
              </div>
            )}
          </div>
        </section>
      </main>
      <footer></footer>
    </>
  )
}

Receipts.propTypes = {
  style: PropTypes.any,
}

export default Receipts
