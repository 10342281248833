import { useNavigate } from 'react-router'
import React, { useEffect, useState } from 'react'
import { ThreeDots, RotatingLines, Circles } from 'react-loader-spinner'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import Error from '../components/Error/ErrorPage'
import useFetchAPI from '../hooks/useFetchAPI'
import {
  GetOutsideTransactionStatus,
  GetReservedBankDetails,
  reset,
} from '../redux/resources/resourceAction'
import Loader from '../components/PageTypeComponents/Loader'
import useSetURL from '../hooks/useSetURL'
const TransferDelay = () => {
  const navigate = useNavigate(),
    dispatch = useDispatch(),
    params = useParams(),
    [visible, setVisiblity] = useState(''),
    [delayed, setDelayStatus] = useState(false),
    {
      isBankDetailsError,
      transactionStatus,
      session,
      merchant,
      BankDetails,
      isBankDetailsLoading,
      baseURL,
    } = useSelector((state) => state.resource),
    Reset = () => {
      dispatch(reset())
    }

  useSetURL()
  useFetchAPI()

  useEffect(() => {
    const handleVisibilityChange = () => {
      setVisiblity(document.visibilityState)
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  useEffect(() => {
    let timeOut = setTimeout(() => {
      if (
        (transactionStatus?.reference ===
          BankDetails?.responseBody?.transactionReference ||
          transactionStatus.merchantCheck === 'success') &&
        visible === 'visible'
      ) {
        navigate(`${baseURL}/end`)
        Reset()
        sessionStorage.removeItem('MNFY_DETAILS')
      }
    }, 5000)
    return () => clearTimeout(timeOut)
  }, [transactionStatus, transactionStatus.merchantCheck, visible])

  useEffect(() => {
    setTimeout(() => {
      setDelayStatus(true)
    }, 60000)
    setTimeout(() => {
      navigate(baseURL)
      Reset()
    }, 120000)
  }, [])

  useEffect(() => {
    const monnifyReference = JSON.parse(sessionStorage.getItem('MNFY_DETAILS'))
    if (!BankDetails) {
      dispatch(GetReservedBankDetails({ monnifyReference }))
    }
  }, [])

  const nonDelayedSetInterval = (
    callback,
    dispatch,
    params,
    session,
    monnifyReference,
    interval
  ) => {
    callback(dispatch, params, session, monnifyReference)
    return setInterval(callback, interval)
  }

  const handleOutsideTransaction = (dispatch, params, session, monnifyReference) => {
    dispatch(
      GetOutsideTransactionStatus({
        merchantGuid: params.merchantGuid,
        sessionGuid: session?.data?.session?.guid,
        reference: monnifyReference,
      })
    )
  }

  useEffect(() => {
    const monnifyReference = JSON.parse(sessionStorage.getItem('MNFY_DETAILS'))
    if (session.data) {
      nonDelayedSetInterval(
        handleOutsideTransaction,
        dispatch,
        params,
        session,
        monnifyReference,
        44500
      )
    }
  }, [session])

  if (isBankDetailsLoading) {
    return <Loader bankTransferPage={true} />
  }

  if (isBankDetailsError) {
    return (
      <Error text={'There might be an issue, try another payment method'} goBack={true} />
    )
  }
  return (
    <>
      <header className="w-full m-auto bg-white flex justify-between min-h-10 pt-5 pb-3 border-b-2 border-solid border-gray-400">
        <nav className="w-container m-auto flex justify-between">
          <RotatingLines
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="26"
            visible={true}
          />

          <div className="w-full flex justify-end">
            <i className="fa-solid text-xl  fa-building-columns mr-4"></i>
            <h2 className="capitalize  font-extrabold font-Catherina text-2xl">
              Pay with Transfer
            </h2>
          </div>
        </nav>
      </header>
      <main className="w-full m-auto">
        <section className="w-full m-auto h-16 bg-gray-100">
          <div className="w-container m-auto h-full text-left">
            <h2 className="text-2xl font-extrabold pt-4">
              Pay
              <span className="text-green-500 mx-2 font-Catherina ">
                {merchant?.data?.currency ? merchant?.data?.currency?.symbol : ''}
              </span>
              {BankDetails?.responseBody?.amount.toLocaleString('en-US') ?? (
                <Circles
                  height="18"
                  width="18"
                  color="#000"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperClass="l"
                  visible={true}
                />
              )}
            </h2>
          </div>
        </section>
        <section className="w-container m-auto mt-14 mb-10">
          {delayed ? (
            <h3 className="text-center font-semibold font-Proxima">
              It&apos;s taking longer than normal to confirm your transfer. But do not
              worry, we will keep trying to confirm your transfer.
            </h3>
          ) : (
            <h3 className="text-center font-Proxima">
              We&apos;re waiting to recieve your transfer. <br />
              This can take up to a minute or two
            </h3>
          )}
        </section>
        <section className="w-container m-auto flex justify-center">
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#4fa94d"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </section>
      </main>
      <aside
        className="w-full flex justify-center mt-16"
        onClick={() => {
          navigate(baseURL)
          Reset()
        }}
      >
        <button className="h-10 bg-gray-200 capitalize px-5 rounded-md">
          <i className="fa-solid fa-xmark mr-2"></i> cancel payment
        </button>
      </aside>
    </>
  )
}

export default React.memo(TransferDelay)
