import { React, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import doubleStroke from '../../images/double.png'
import { useDispatch, useSelector } from 'react-redux'
import { setSplittedBill } from '../../redux/resources/resourceAction'

const SplitModal = () => {
  const { merchant, billInfo, tipAmount, splittedBill } = useSelector(
      (state) => state.resource
    ),
    dispatch = useDispatch(),
    [modal, setModal] = useState(false),
    [Value, setValue] = useState(),
    SplitModeCancel = () => {
      setModal(!modal)
      setValue(0)
      dispatch(
        setSplittedBill({
          splittedBill: 0,
        })
      )
    }

  return (
    <>
      <button
        className="bg-white border-2 mt-2 border-black hover:bg-white tracking-wide text-black w-full text-lg font-Proxima font-bold py-2 px-4 rounded-lg capitalize"
        onClick={() => setModal(!modal)}
      >
        Split bill
      </button>
      {modal && (
        <div className="modal">
          <div className="overlay__black" onClick={() => setModal(!modal)}></div>
          <section className="absolute top-50% bg-white  w-full rounded-t-3xl h-full overflow-auto">
            <div className="w-container m-auto">
              <div className="w-full h-10 flex mt-4">
                <h1 className="text-4xl font-Catherina capitalize font-extrabold pt-3">
                  Split bill
                </h1>
                <i className="fa-regular fa-newspaper  pt-3 text-3xl mx-3"></i>
              </div>
              <div className="w-full h-10 flex mt-5 justify-between text-xl font-Proxima font-semibold">
                <h2> Table bill</h2>
                <h2>
                  <span>
                    {merchant?.data?.currency ? merchant?.data?.currency.symbol : ''}
                  </span>
                  {splittedBill === 0
                    ? Math.ceil(
                        billInfo?.data?.outstandingAmount + tipAmount
                      ).toLocaleString('en-US')
                    : Math.ceil(billInfo?.data?.outstandingAmount).toLocaleString(
                        'en-US'
                      )}
                </h2>
              </div>

              <div className="text-center font-Proxima text-lg">
                <h2>How much would you like to pay?</h2>
              </div>

              <div className="modal__input">
                <div>
                  <CurrencyInput
                    prefix={merchant?.data?.currency && merchant?.data?.currency.symbol}
                    className="input__split"
                    placeholder={
                      merchant?.data?.currency && merchant?.data?.currency.symbol + '0.00'
                    }
                    name="bill"
                    autoFocus
                    pattern="[0-9]*"
                    inputMode="numeric"
                    type={'text'}
                    defaultValue={Value}
                    onValueChange={(value) => setValue(value)}
                  />
                </div>
                <img src={doubleStroke} alt="double_stroke" />
              </div>

              <div className="mt-10 flex justify-between">
                <button
                  className="bg-white border-2 flex justify-around border-black hover:bg-white tracking-wide text-black text-lg h-12 font-Proxima font-bold w-2/5 rounded-lg capitalize pt-2"
                  onClick={SplitModeCancel}
                >
                  cancel
                </button>
                <button
                  className="bg-black border-2 flex justify-around border-black hover:bg-white tracking-wide text-white text-lg h-12 font-Proxima font-bold w-2/5 rounded-lg capitalize pt-2"
                  onClick={() => {
                    setModal(!modal)
                    if (Value) {
                      dispatch(
                        setSplittedBill({
                          splittedBill: Number(Value),
                        })
                      )
                    } else {
                      dispatch(
                        setSplittedBill({
                          splittedBill: Number(0),
                        })
                      )
                    }
                  }}
                >
                  confirm
                </button>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  )
}

export default SplitModal
