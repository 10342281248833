import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { setTipAmount } from '../../redux/resources/resourceAction'
import MoneyIcon from '../../images/33-337047_transparent-model-icon-png-give-money-illustration-png.png'
import React from 'react'

const SingleTip = ({ state, index }) => {
  const { merchant, tipAmount } = useSelector((state) => state.resource),
    dispatch = useDispatch()

  const SetTipAmount = () => {
    dispatch(
      setTipAmount({
        tipAmount: tipAmount === Number(state) ? 0 : Number(state),
      })
    )
  }

  return (
    <div key={index}>
      <div
        className={
          tipAmount === Number(state)
            ? 'h-28 bg-black border-tip border-2 rounded-10px mr-4 shadow-tip border-solid'
            : 'h-28 bg-modal border-tip border-2 rounded-10px mr-4 shadow-tip border-solid'
        }
        onClick={SetTipAmount}
      >
        {tipAmount === Number(state) && (
          <div className="w-full flex justify-end">
            <div className=" w-3 h-3 rounded-full mr-1 mt-1 bg-green-600 "></div>
          </div>
        )}
        <div
          className={
            tipAmount === Number(state)
              ? 'w-12 mx-4 m-auto h-12 rounded-full bg-tip mt-2'
              : 'w-14 mx-3 m-auto h-14 rounded-full bg-modal mt-1'
          }
        >
          <img
            src={MoneyIcon}
            alt="winged money"
            className={
              tipAmount === Number(state)
                ? 'w-12 m-auto p-1  rounded-full'
                : 'w-12 m-auto pt-2 rounded-full'
            }
          />
        </div>
        <h2
          className={
            tipAmount === Number(state)
              ? 'mt-3 text-center text-white text-lg font-Proxima'
              : 'mt-3 text-center text-black text-lg font-Proxima'
          }
        >
          {merchant?.data?.currency ? merchant?.data?.currency.symbol : ''}
          {Number(state).toLocaleString('en-US')}
        </h2>
      </div>
    </div>
  )
}

SingleTip.propTypes = {
  state: PropTypes.any,
  index: PropTypes.number,
}

export default React.memo(SingleTip)
