import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { setSessionSettledStatus } from '../redux/resources/resourceAction'

const useSessionSettled = () => {
  const { sessionSettled, baseURL } = useSelector((state) => state.resource)
  const navigation = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (sessionSettled) {
      navigation(`${baseURL}/bill`)
      dispatch(setSessionSettledStatus({ status: false }))
    }
  }, [sessionSettled])
}

export default useSessionSettled
