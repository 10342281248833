import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import SingleItem from './SingleItem'

const MenuBody = ({ products, order }) => {
  const { selectedCategory, merchant } = useSelector((state) => state.resource),
    [googleFont, setGoogleFont] = useState(''),
    [cdnFont, setCDNFont] = useState('')

  useEffect(() => {
    if (merchant?.data?.menuFont) {
      const CDNBase = process.env.REACT_APP_CDN_FONT_BASE
      const GoogleBase = process.env.REACT_APP_GOOGLE_FONT_BASE

      setGoogleFont(
        `${GoogleBase}family=${merchant?.data?.menuFont
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
          .replace(/ /g, '+')}`
      )
      setCDNFont(`${CDNBase}${merchant?.data?.menuFont.replace(/ /g, '-')}`)
    }
  }, [])
  return (
    <>
      <div>
        {merchant?.data?.menuFont && (
          <>
            <link href={googleFont} rel="stylesheet"></link>
            <link href={cdnFont} rel="stylesheet"></link>
          </>
        )}
      </div>
      {
        <section className="bg-restaurant-pattern w-screen min-h-60 mt-48">
          {products
            .filter((state) => state?.merchantProductCategory?.guid === selectedCategory)
            .sort((a, b) => new Date(a.created) - new Date(b.created))
            .map((state) => (
              <div key={state.slug}>
                <SingleItem state={state} order={order} />
              </div>
            ))}
        </section>
      }
    </>
  )
}

MenuBody.propTypes = {
  products: PropTypes.any,
  order: PropTypes.any,
}

export default React.memo(MenuBody)
