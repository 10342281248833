import * as signalR from '@microsoft/signalr'

class MerchantConnector {
  private connection: signalR.HubConnection
  public merchantEvents: (
    onSendToGroup: (
      dispatch: any,
      params: any,
      merchantGuid: string,
      sessionGuid: string,
      type: string,
      message: any,
      sessionOnDevice: string
    ) => void
  ) => void
  static instance: MerchantConnector

  constructor(
    url: string,
    merchantGuid: string,
    sessionGuid: string,
    sessionOnDevice: string,
    dispatch: any,
    params: any
  ) {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(url)
      .withAutomaticReconnect()
      .build()

    this.connection
      .start()
      .then(() => {
        this.connection.invoke('AddToGroup', merchantGuid, sessionGuid)
        console.log('connection started for merchant')
      })
      .catch((err) => document.write(err))

    this.merchantEvents = (onSendToGroup) => {
      this.connection.on(
        'SendMessageToGroup',
        (merchantGuid, sessionGuid, type, message) => {
          onSendToGroup(
            dispatch,
            params,
            merchantGuid,
            sessionGuid,
            type,
            message,
            sessionOnDevice
          )
        }
      )
    }
  }

  public static getInstance(
    url: string,
    merchantGuid: string,
    sessionGuid: string,
    sessionOnDevice: string,
    dispatch: any,
    params: any
  ): MerchantConnector {
    if (!MerchantConnector.instance)
      MerchantConnector.instance = new MerchantConnector(
        url,
        merchantGuid,
        sessionGuid,
        sessionOnDevice,
        dispatch,
        params
      )
    return MerchantConnector.instance
  }
}
export default MerchantConnector.getInstance
