import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import App from './App'
import store from './redux/store'
import ErrorBoundary from './components/Error/ErrorBoundary'
import seq from 'seq-logging'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </Provider>
)

const serverUrl = process.env.REACT_APP_LOGGING_SERVER_URL

const logger = new seq.Logger({
  serverUrl: serverUrl,
})

reportWebVitals((metric) => {
  logger.emit({
    timestamp: new Date(),
    level: 'Information',
    messageTemplate: 'Outside Core web vitals metric ',
    properties: {
      ...metric,
    },
  })
})
