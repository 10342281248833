import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Footer } from '../components/Footer'
import Loader from '../components/PageTypeComponents/Loader'
import MenuBody from '../components/Menu/MenuBody'
import MenuHeader from '../components/Menu/MenuHeader'
import Error from '../components/Error/ErrorPage'
import { useParams } from 'react-router-dom'
import useFetchAPI from '../hooks/useFetchAPI'
import useRealTimeUpdates from '../hooks/useRealTime'
import usePermanentInformation from '../hooks/usePermanentInfomation'
import useSessionSettled from '../hooks/useSessionSettled'
import useSetURL from '../hooks/useSetURL'
import useSessionExpired from '../hooks/useSessionExpired'
import { GetSession } from '../redux/resources/resourceAction'

const Menu = () => {
  const navigation = useNavigate(),
    [animation, setAnimation] = useState(false),
    [SideDisplay, SetSideDisplay] = useState(false),
    {
      isProductLoading,
      orderApproved,
      isProductError,
      isCategoryError,
      isCategoryLoading,
      isMerchantLoading,
      isMerchantError,
      isHubError,
      isHubLoading,
      products,
      category,
      selectedCategory,
      orderLength,
      order,
      hubInfo,
      merchant,
      baseURL,
      session,
    } = useSelector((state) => state.resource),
    params = useParams(),
    dispatch = useDispatch()

  useRealTimeUpdates(params.merchantGuid)
  usePermanentInformation()
  useFetchAPI()
  useSetURL()
  useSessionExpired()
  useEffect(() => {
    if (order && order?.data) {
      if (orderLength > 0) {
        setAnimation(true)
        SetSideDisplay(true)
        if (!session?.data) {
          dispatch(
            GetSession({
              Guid: params.merchantGuid,
              hubGuid: params.hubGuid,
            })
          )
        }
      } else {
        setAnimation(false)
        SetSideDisplay(false)
      }
    }
  }, [orderLength, order])
  useEffect(() => {
    if (orderApproved) {
      navigation(baseURL)
    }
  }, [orderApproved])

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [selectedCategory])

  useSessionSettled()

  if (isProductLoading || isCategoryLoading || isMerchantLoading || isHubLoading) {
    return <Loader banktransferPage={false} />
  }
  if (
    (isProductError || isCategoryError || isMerchantError || isHubError) &&
    !isProductLoading &&
    !isCategoryLoading &&
    !isMerchantLoading &&
    !isHubLoading
  ) {
    return (
      <Error
        text={'There might be an issue with your connection. Kindly reload to continue.'}
        status={true}
      />
    )
  }
  if (!merchant || merchant.data === null) {
    return <Error text={'Merchant not found'} status={false} />
  }

  if (!category.succeeded || products.length === 0) {
    return <Error text={'Menu not available'} status={true} />
  }

  if (!hubInfo || !hubInfo.data || !hubInfo.succeeded) {
    return <Error text={'Table not Found'} status={false} />
  }

  if (merchant.succeeded && category.succeeded && hubInfo.succeeded) {
    return (
      <>
        <MenuHeader animation={animation} />
        <MenuBody products={products} order={order} />

        {SideDisplay && (
          <aside
            className="fixed top-96 w-20 left-3/4 ml-7 bg-white rounded-lg border-2 border-grey rotate-22.5 lg:invisible "
            onClick={() => {
              navigation(`${baseURL}/table`)
            }}
          >
            <h1 className="font-Manslava text-cyan-700 text-center capitalize font-extrabold text-2xl">
              View
              <br /> Table
              <br /> Order
            </h1>
          </aside>
        )}
        <Footer />
      </>
    )
  }
  return (
    <Error
      text={'There might be an issue with your connection. Kindly reload to continue.'}
      status={true}
    />
  )
}

export default React.memo(Menu)
