import * as signalR from '@microsoft/signalr'

class CronConnector {
  private connection: signalR.HubConnection
  public cronEvents: (
    onSendToGroup: (
      dispatch: any,
      params: any,
      merchantGuid: string,
      sessionGuid: string,
      type: string,
      message: any,
      sessionOnDevice: string
    ) => void
  ) => void
  static instance: CronConnector

  constructor(
    url: string,
    merchantGuid: string,
    sessionGuid: string,
    sessionOnDevice: string,
    dispatch: any,
    params: any
  ) {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(url)
      .withAutomaticReconnect()
      .build()

    this.connection
      .start()
      .then(() => {
        this.connection.invoke('AddToGroup', merchantGuid, sessionGuid)
        console.log('connection started for cron')
      })
      .catch((err) => document.write(err))

    this.cronEvents = (onSendToGroup) => {
      this.connection.on(
        'SendMessageToGroup',
        (merchantGuid, sessionGuid, type, message) => {
          onSendToGroup(
            dispatch,
            params,
            merchantGuid,
            sessionGuid,
            type,
            message,
            sessionOnDevice
          )
        }
      )
    }
  }

  public static getInstance(
    url: string,
    merchantGuid: string,
    sessionGuid: string,
    sessionOnDevice: string,
    dispatch: any,
    params: any
  ): CronConnector {
    if (!CronConnector.instance)
      CronConnector.instance = new CronConnector(
        url,
        merchantGuid,
        sessionGuid,
        sessionOnDevice,
        dispatch,
        params
      )
    return CronConnector.instance
  }
}
export default CronConnector.getInstance
