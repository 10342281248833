import React from 'react'
import { Footer } from '../components/Footer'

const Home = () => {
  return (
    <>
      <header className="home__header"></header>
      <main className="w-screen h-2/5 mt-12">
        <section className="flex justify-center w-full">
          <div className="h-4/5 w-full">
            <h1 className="font-Catherina text-center capitalize font-extrabold text-6xl">
              Welcome,
            </h1>
            <p className="font-Catherina capitalize mt-10 text-center font-extrabold text-2xl">
              scan the QR code to view the menu
            </p>
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}

export default Home
