import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import {
  GetHubInfo,
  GetMerchant,
  getHubFromStorage,
  getMerchantFromStorage,
} from '../redux/resources/resourceAction'

const usePermanentInformation = () => {
  const dispatch = useDispatch(),
    { merchant, hubInfo } = useSelector((state) => state.resource),
    params = useParams()

  useEffect(() => {
    if (!merchant?.succeeded || !merchant) {
      const merchantFromStorage = JSON.parse(sessionStorage.getItem('Merchant'))
      if (merchantFromStorage) {
        dispatch(getMerchantFromStorage({ merchant: merchantFromStorage }))
      } else {
        dispatch(
          GetMerchant({
            Guid: params.merchantGuid,
          })
        )
      }
    }
    if (!hubInfo || !hubInfo.succeeded) {
      const hubFromStorage = JSON.parse(sessionStorage.getItem('Hub'))
      if (hubFromStorage) {
        dispatch(getHubFromStorage({ hubInfo: hubFromStorage }))
      } else {
        dispatch(
          GetHubInfo({
            Guid: params.merchantGuid,
            hubGuid: params.hubGuid,
          })
        )
      }
    }
  }, [])
}

export default usePermanentInformation
