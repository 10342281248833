import { useDispatch, useSelector } from 'react-redux'
import {
  onOrderApproval,
  realtimeOrderUpdate,
  setSessionSettledStatus,
  updateTransactionStatus,
} from '../redux/resources/resourceAction'
import apiConnector from '../services/realtime/connector'
import merchantConnector from '../services/realtime/merchantConnector'
import cronConnector from '../services/realtime/cronConnector'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

const apiUrl = process.env.REACT_APP_OUTSIDE_REALTIME
const cronUrl = process.env.REACT_APP_OUTSIDE_CRON_REALTIME
const merchantUrl = process.env.REACT_APP_OUTSIDE_MERCHANT_REALTIME

const useRealTimeUpdates = () => {
  const params = useParams()
  const dispatch = useDispatch()

  const { merchant, session } = useSelector((state) => state.resource)

  useEffect(() => {
    if (merchant?.data?.guid && session?.data?.guid) {
      const { events } = apiConnector(
        apiUrl,
        merchant?.data?.guid,
        session?.data?.guid,
        session?.data?.session?.guid,
        dispatch,
        params
      )

      if (merchant?.data?.guid && session?.data?.guid) {
        events(RealTimeActionHandlers)
      }

      const { merchantEvents } = merchantConnector(
        merchantUrl,
        merchant?.data?.guid,
        session?.data?.guid,
        session?.data?.session?.guid,
        dispatch,
        params
      )

      if (merchant?.data?.guid && session?.data?.guid) {
        merchantEvents(RealTimeActionHandlers)
      }

      const { cronEvents } = cronConnector(
        cronUrl,
        merchant?.data?.guid,
        session?.data?.guid,
        session?.data?.session?.guid,
        dispatch,
        params
      )

      if (merchant?.data?.guid && session?.data?.guid) {
        cronEvents(RealTimeActionHandlers)
      }
    }
  }, [merchant, session])
}
export default useRealTimeUpdates

const RealTimeActionHandlers = (
  dispatch,
  params,
  merchantGuid,
  sessionGuid,
  type,
  message,
  sessionGuidOnDevice
) => {
  if (sessionGuid === sessionGuidOnDevice) {
    switch (type) {
      case 'ORDER_UPDATE':
        dispatch(realtimeOrderUpdate({ data: message.order }))
        break

      case 'ORDER_APPROVED':
        dispatch(onOrderApproval({ status: true }))
        break

      case 'TRANSACTION_UPDATE':
        dispatch(
          updateTransactionStatus({
            isSuccessful: true,
            reference: message.reference,
            merchantCheck: '',
          })
        )
        break

      case 'SESSION_SETTLED':
        dispatch(setSessionSettledStatus({ status: true }))
        break

      default:
        break
    }
  }
}
