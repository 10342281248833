import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'

const useSessionExpired = () => {
  const params = useParams(),
    { session } = useSelector((state) => state.resource),
    billInStorage = JSON.parse(sessionStorage.getItem('Bill')),
    navigate = useNavigate()
  useEffect(() => {
    let timeOut
    if (params.sessionGuid) {
      if (params.sessionGuid !== session?.data?.session?.guid) {
        if (!billInStorage) {
          navigate('/')
          sessionStorage.clear()
        } else {
          timeOut = setTimeout(() => {
            navigate('/')
          }, 240000)
        }
      }
    }
    return () => clearTimeout(timeOut)
  }, [session])
}

export default useSessionExpired
