import React from 'react'
import { useNavigate } from 'react-router'
import ErrorImage from '../../images/SVG-Animation-404-Pag.gif'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const Error = ({ text, secondText, status, goBack, goHome }) => {
  const { baseURL } = useSelector((state) => state.resource)
  const navigate = useNavigate()
  function reload() {
    window.location.reload()
  }
  return (
    <main className="h-screen bg-contain bg-restaurant-pattern">
      <article className="w-80 m-auto pt-20">
        <h3 className="text-black text-4xl font-extrabold capitalize text-center font-Catherina pb-9">
          oops!!!
        </h3>
        <img src={ErrorImage} alt="error" className="im" />
        <p className="text-center text-xl mt-9 font-Proxima">
          {text ?? 'something went wrong'}
        </p>
        <p className="text-center text-xl  font-Proxima">{secondText}</p>
        {status && (
          <button
            className="bg-black hover:bg-black tracking-widest mt-9 text-white w-full text-lg font-Proxima font-bold py-2 px-4 rounded-lg capitalize"
            onClick={reload}
          >
            reload
          </button>
        )}
        {goBack && (
          <button
            className="bg-black hover:bg-black tracking-widest mt-9 text-white w-full text-lg font-Proxima font-bold py-2 px-4 rounded-lg capitalize"
            onClick={() => navigate(-1)}
          >
            go Back
          </button>
        )}
        {goHome && (
          <button
            className="bg-black hover:bg-black tracking-widest mt-9 text-white w-full text-lg font-Proxima font-bold py-2 px-4 rounded-lg capitalize"
            onClick={() => navigate(baseURL)}
          >
            go Home
          </button>
        )}
      </article>
    </main>
  )
}

Error.propTypes = {
  text: PropTypes.string,
  secondText: PropTypes.string,
  goBack: PropTypes.bool,
  status: PropTypes.bool,
  goHome: PropTypes.bool,
  merchantGuid: PropTypes.string,
  hubGuid: PropTypes.string,
}

export default Error
