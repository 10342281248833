import { React, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import finalImage from '../images/79952-successful.gif'
import gif from '../images/the origina.gif'
import { useDispatch, useSelector } from 'react-redux'
import { reset } from '../redux/resources/resourceAction'
const Final = () => {
  const navigation = useNavigate(),
    dispatch = useDispatch(),
    { baseURL } = useSelector((state) => state.resource)

  useEffect(() => {
    dispatch(reset())
    sessionStorage.removeItem('MNFY_DETAILS')
    const timeOut = setTimeout(() => navigation(baseURL), 4200)

    return () => clearTimeout(timeOut)
  }, [navigation, dispatch])
  return (
    <>
      <header className=" container final__header"></header>
      <main className="main__final container">
        <section className="w-full m-auto flex justify-center">
          <img
            className="h-52 my-3 w-52"
            src={finalImage}
            alt="final_logo_goodluck"
          ></img>
        </section>
        <section className="final__illustration">
          <h2>Payment Successful!!</h2>
          <img src={gif} alt="clapping_gif" className="final__giphy" />
        </section>
      </main>
      <footer className="footer final"></footer>
    </>
  )
}

export default Final
