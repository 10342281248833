import React from 'react'
import OutsideLogo from '../images/logo.png'

export const Footer = () => {
  return (
    <footer className="w-screen flex mt-2 justify-center">
      <h3 className="font-Catherina CatherinaFont text-center text-xl mr-1">
        Powered by
      </h3>
      <img src={OutsideLogo} className="h-8 w-20" alt="Outside_logo" />
    </footer>
  )
}
