const HomePageErrorModal = () => {
  return (
    <div className="modal">
      <div className="overlay__black">
        <section className=" bg-white  w-3/5 m-auto mt-64 rounded-xl h-20 overflow-auto">
          <h2 className="text-center my-2 font-Proxima">Something went wrong</h2>

          <div className="flex mt-3 justify-center w-full">
            <button
              className="h-7 w-20 bg-black text-white 
              shadow-md rounded-md capitalize hover:shadow-xl 
              hover:border-solid hover:border-black"
              onClick={() => window.location.reload()}
            >
              reload
            </button>
          </div>
        </section>
      </div>
    </div>
  )
}

export default HomePageErrorModal
