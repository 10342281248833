import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PostConsolidateSession } from '../redux/resources/resourceAction'
import { useParams } from 'react-router'

const useConsolidate = () => {
  const dispatch = useDispatch(),
    params = useParams(),
    { session, billInfo } = useSelector((state) => state.resource)
  useEffect(() => {
    const interval = setInterval(() => {
      if (!billInfo?.fromLocalStorage) {
        if (billInfo?.data?.paidAmount !== 0) {
          session?.data &&
            dispatch(
              PostConsolidateSession({
                merchantGuid: params.merchantGuid,
                sessionGuid: params.sessionGuid ?? session?.data?.session?.guid,
              })
            )
        }
      }
    }, 120000)
    return () => clearInterval(interval)
  }, [billInfo])
}

export default useConsolidate
