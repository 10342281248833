import { React, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UpdateOrder } from '../../redux/resources/resourceAction'
import { useParams } from 'react-router'
import PropTypes from 'prop-types'

const EditQuantityIcon = ({ foodName, quantity, id }) => {
  const { order } = useSelector((state) => state.resource),
    params = useParams(),
    dispatch = useDispatch(),
    [modal, setModal] = useState(false),
    [Value, setValue] = useState(quantity),
    thisItem = order?.data ? order?.data.details.find((data) => data.guid === id) : false

  function updateQuantity() {
    dispatch(
      UpdateOrder({
        Guid: params.merchantGuid,
        orderGuid: order?.data.guid,
        quantity: Value,
        productGuid: thisItem.merchantProduct.guid,
        orderDetailsGuid: thisItem.guid,
      })
    )
  }

  return (
    <>
      <i
        className="fa-solid fa-pen-to-square text-neutral-400 mx-2  text-sm"
        onClick={() => setModal(!modal)}
      ></i>

      {modal && (
        <div className="modal">
          <div className="overlay__black" onClick={() => setModal(!modal)}></div>
          <section className="absolute top-55% bg-white  w-full rounded-t-3xl h-full overflow-auto">
            <div className="w-container m-auto">
              <div className="w-full min-h-1 flex mt-4">
                <h1 className="text-4xl font-Catherina text-center capitalize font-extrabold pt-3">
                  {foodName}
                </h1>
              </div>

              <h3 className="w-full text-center font-Catherina my-2">
                Add/Reduce quantity
              </h3>

              <div className="w-4/5 m-auto h-10 flex justify-evenly">
                <button
                  className="w-12 h-12 bg-neutral-300 rounded shadow-md"
                  onClick={() => {
                    Value > 1 && setValue((prevState) => --prevState)
                  }}
                >
                  <i className="fa-solid fa-minus "></i>
                </button>
                <h3 className="text-xl mt-2 font-Proxima">{Value}</h3>
                <button
                  className="w-12 h-12 bg-neutral-300 rounded shadow-md"
                  onClick={() => {
                    setValue((prevState) => ++prevState)
                  }}
                >
                  <i className="fa-solid fa-plus"></i>
                </button>
              </div>
              <button
                className="m-auto mt-9 font-Catherina  bg-neutral-300 rounded-md border-2 border-solid px-9 py-2 flex "
                onClick={() => {
                  setModal(!modal)
                  updateQuantity()
                }}
              >
                Ok
              </button>
            </div>
          </section>
        </div>
      )}
    </>
  )
}

EditQuantityIcon.propTypes = {
  foodName: PropTypes.string,
  quantity: PropTypes.number,
  id: PropTypes.string,
}

export default EditQuantityIcon
