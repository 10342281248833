import React, { useState, useRef } from 'react'
import SingleTip from './SingleTip'
import CustomizedTip from './CustomizedTip'

const TipSection = () => {
  const [moreDisplay, SetMoreDisplay] = useState(0),
    scrollRef = useRef(null),
    ScrollingTipSection = (event) => {
      SetMoreDisplay(event.currentTarget.scrollLeft)
    },
    ScrollArrows = (direction) => {
      if (direction === 'backward') {
        scrollRef.current.scrollLeft -= 300
      } else {
        scrollRef.current.scrollLeft += 300
      }
    },
    tips = process.env.REACT_APP_TIP_AMOUNTS.split(',')

  return (
    <aside className="w-full">
      <div className="w-full mt-9 mb-2">
        <h2 className="text-start font-Proxima font-extrabold">Tip the waiter?</h2>
        <h2 className="text-start font-Proxima font-normal">
          (This goes directly to the waiter that served you)
        </h2>
      </div>

      <div className="flex w-container m-auto justify-center h-36">
        {moreDisplay > 40 && (
          <div className="arrowState">
            <i
              className="fa-solid fa-chevron-left icon"
              onClick={() => ScrollArrows('backward')}
            ></i>
          </div>
        )}
        <div className="bill__tip-option" onScroll={ScrollingTipSection} ref={scrollRef}>
          {tips.map((state, index) => (
            <div key={index}>
              <SingleTip state={state} index={index} />
            </div>
          ))}
          <CustomizedTip />
          <div className="w-10"></div>
        </div>
        {moreDisplay < 1 && (
          <div className="arrowState md:invisible lg:invisible xl:invisible">
            <i
              className="fa-solid fa-chevron-right icon"
              onClick={() => ScrollArrows('forward')}
            ></i>
          </div>
        )}
      </div>
    </aside>
  )
}

export default React.memo(TipSection)
