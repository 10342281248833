import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ResourceServices } from '../resources/resourceSlice'

const initialState = {
  isMerchantError: false,
  isMerchantSuccess: false,
  isMerchantLoading: false,
  isCategorySuccess: false,
  isCategoryError: false,
  isCategoryLoading: false,
  isOrderLoading: false,
  isOrderSuccess: false,
  isOrderError: false,
  isProductSuccess: false,
  isProductError: false,
  isProductLoading: false,
  isBankDetailsLoading: false,
  isBankDetailsSuccess: false,
  isBankDetailsError: false,
  isHubSuccess: false,
  isHubError: false,
  isHubLoading: false,
  isBillsLoading: false,
  isBillsSuccess: false,
  isBillsError: false,
  isSessionSuccess: false,
  isSessionError: false,
  isSessionLoading: false,
  hubInfo: '',
  message: '',
  session: '',
  category: [],
  products: [],
  selectedCategory: '',
  merchant: '',
  order: '',
  orderLength: 0,
  BankDetails: null,
  orderGuid: '',
  tipAmount: 0,
  splittedBill: 0,
  parentCategory: '',
  accountTimer: 0,
  billInfo: null,
  bankMessage: '',
  transactionStatus: {
    isSuccessful: false,
    reference: '',
    merchantCheck: '',
  },
  orderApproved: false,
  isTimerLoading: false,
  monnifySuccess: false,
  monnifyAuthorization: '',
  sessionSettled: false,
  baseURL: '',
}

export const GetProducts = createAsyncThunk(
  'resource/GetProducts',
  async (merdchantDetails, thunkAPI) => {
    try {
      return await ResourceServices.getProductsPerCategory(merdchantDetails)
    } catch (error) {
      const message = error.response.data
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const GetCategories = createAsyncThunk(
  'resource/GetCategories',
  async (merdchantDetails, thunkAPI) => {
    try {
      return await ResourceServices.getCategories(merdchantDetails)
    } catch (error) {
      const message = error.response.data
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const GetMerchant = createAsyncThunk(
  'resource/GetMerchant',
  async (merdchantDetails, thunkAPI) => {
    try {
      return await ResourceServices.getMerchant(merdchantDetails)
    } catch (error) {
      const message = error.response.data
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const GetHappyHour = createAsyncThunk(
  'resource/GetCategories',
  async (merchantDetails, thunkAPI) => {
    try {
      return await ResourceServices.getHappyHour(merchantDetails)
    } catch (error) {
      const message = error.response.data
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const GetSession = createAsyncThunk(
  'resource/GetSession',
  async (details, thunkAPI) => {
    try {
      return await ResourceServices.getSession(details)
    } catch (error) {
      const message = error.response.data
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const GetOrders = createAsyncThunk(
  'resource/GetOrders',
  async (merdchantDetails, thunkAPI) => {
    try {
      return await ResourceServices.getOrders(merdchantDetails)
    } catch (error) {
      const message = error.response.data
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const GetOrderInBackground = createAsyncThunk(
  'resource/GetOrderInBackground',
  async (merdchantDetails, thunkAPI) => {
    try {
      return await ResourceServices.getOrders(merdchantDetails)
    } catch (error) {
      const message = error.response.data
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const GetHubInfo = createAsyncThunk(
  'resource/GetHubInfo',
  async (merchantDetails, thunkAPI) => {
    try {
      return await ResourceServices.getHubInfo(merchantDetails)
    } catch (error) {
      const message = error.response.data
      return thunkAPI.rejectWithValue(message)
    }
  }
)
export const CreateOrder = createAsyncThunk(
  'resource/CreateOrder',
  async (merchantDetails, thunkAPI) => {
    try {
      return await ResourceServices.createOrder(merchantDetails)
    } catch (error) {
      const message = error.response.data
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const AddToOrder = createAsyncThunk(
  'resource/AddToOrder',
  async (merchantDetails, thunkAPI) => {
    try {
      return await ResourceServices.addToOrder(merchantDetails)
    } catch (error) {
      const message = error.response.data
      return thunkAPI.rejectWithValue(message)
    }
  }
)
export const UpdateOrder = createAsyncThunk(
  'resource/UpdateOrder',
  async (merchantDetails, thunkAPI) => {
    try {
      return await ResourceServices.updateOrder(merchantDetails)
    } catch (error) {
      const message = error.response.data

      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const DeleteOrderItems = createAsyncThunk(
  'resource/DeleteItem',
  async (merchantDetails, thunkAPI) => {
    try {
      return await ResourceServices.deleteOrderItems(merchantDetails)
    } catch (error) {
      const message = error.response.data
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const ConfirmPayment = createAsyncThunk(
  'resource/ConfirmPayment',
  async (merdchantDetails, thunkAPI) => {
    try {
      return await ResourceServices.confirmPayment(merdchantDetails)
    } catch (error) {
      const message = error.response.data
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const PostTransaction = createAsyncThunk(
  'resource/PostTransaction',
  async (merchantDetails, thunkAPI) => {
    try {
      return await ResourceServices.postTransaction(merchantDetails)
    } catch (error) {
      const message = error.response.data
      return thunkAPI.rejectWithValue(message)
    }
  }
)
export const GetReservedBankDetails = createAsyncThunk(
  'resource/GetReservedAccountDetails',
  async (details, thunkAPI) => {
    try {
      return await ResourceServices.getReservedBankDetails(details)
    } catch (error) {
      const message = error.response.data.responseMessage
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const GetTimer = createAsyncThunk(
  'resource/GetTimer',
  async (details, thunkAPI) => {
    try {
      return await ResourceServices.getReservedBankDetails(details)
    } catch (error) {
      const message = error.response.data.responseMessage
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const GetTableBill = createAsyncThunk(
  'resource/GetTableBill',
  async (details, thunkAPI) => {
    try {
      return await ResourceServices.getTableBill(details)
    } catch (error) {
      const message = error.response.data
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const PostConsolidateSession = createAsyncThunk(
  'resource/PostConsolidateSession',
  async (details, thunkAPI) => {
    try {
      return await ResourceServices.postConsolidateSession(details)
    } catch (error) {
      const message = error.response.data
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const CloseOutOrders = createAsyncThunk(
  'resource/CloseOutOrders',
  async (details, thunkAPI) => {
    try {
      return await ResourceServices.closeOutOrders(details)
    } catch (error) {
      const message = error.response.data
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const MonnifyLogin = createAsyncThunk(
  'resource/MonnifyLogin',
  async (thunkAPI) => {
    try {
      return await ResourceServices.monnifyLogin()
    } catch (error) {
      const message = error.response.data.responseMessage
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const GetOutsideTransactionStatus = createAsyncThunk(
  'resource/GetOutsideTransactionStatus',
  async (details, thunkAPI) => {
    try {
      return await ResourceServices.checkTransactionStatusOnOutside(details)
    } catch (error) {
      const message = error.response.data
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const ResourceSlice = createSlice({
  name: 'resource',
  initialState,
  reducers: {
    reset: (state) => {
      state.splittedBill = 0
      state.tipAmount = 0
      state.selectedCategory = null
      state.parentCategory = null
      state.bankMessage = ''
      state.transactionStatus = {
        reference: '',
        isSuccessful: false,
        merchantCheck: '',
      }
      state.orderApproved = false
    },
    setSplittedBill: (state, { payload }) => {
      state.splittedBill = payload.splittedBill
      sessionStorage.removeItem('MNFY_DETAILS')
    },
    setTipAmount: (state, { payload }) => {
      state.tipAmount = payload.tipAmount
      sessionStorage.removeItem('MNFY_DETAILS')
    },
    setCategory: (state, { payload }) => {
      state.selectedCategory = payload.category
    },
    setParentCategory: (state, { payload }) => {
      state.parentCategory = payload.parentCategory
    },
    getBillFromStorage: (state, { payload }) => {
      state.billInfo = payload.bill
    },
    updateTransactionStatus: (state, { payload }) => {
      state.transactionStatus = payload
    },
    onOrderApproval: (state, { payload }) => {
      state.orderApproved = payload.status
    },
    realtimeOrderUpdate: (state, { payload }) => {
      state.order = payload
      state.orderGuid = payload.data.guid
      state.orderLength = payload.data.details.length
    },
    getHubFromStorage: (state, { payload }) => {
      state.hubInfo = payload.hubInfo
    },
    getMerchantFromStorage: (state, { payload }) => {
      state.merchant = payload.merchant
    },
    setSessionSettledStatus: (state, { payload }) => {
      state.sessionSettled = payload.status
    },
    setBaseURL: (state, { payload }) => {
      state.baseURL = payload.url
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetCategories.pending, (state) => {
        state.isCategoryLoading = true
        state.isCategorySuccess = false
        state.isCategoryError = false
        state.category = []
        state.selectedCategory = ''
      })
      .addCase(GetCategories.fulfilled, (state, action) => {
        state.isCategoryLoading = false
        state.isCategoryError = false
        state.isCategorySuccess = true
        state.category = action.payload
        if (action.payload.data.length > 0) {
          state.selectedCategory = null
        }
      })
      .addCase(GetCategories.rejected, (state, action) => {
        state.isCategoryError = true
        state.isCategoryLoading = false
        state.isCategorySuccess = false
        state.category = action.payload
        state.selectedCategory = ''
      })
      .addCase(GetProducts.pending, (state) => {
        state.isProductLoading = true
        state.isProductSuccess = false
        state.isProductError = false
        state.products = []
      })
      .addCase(GetProducts.fulfilled, (state, action) => {
        state.isProductLoading = false
        state.isProductSuccess = true
        state.isProductError = false
        action.payload.data.forEach((element) => {
          state.products.push(element)
        })
      })
      .addCase(GetProducts.rejected, (state, action) => {
        state.isProductLoading = false
        state.isProductSuccess = false
        state.isProductError = true
        state.products = action.payload
      })
      .addCase(GetMerchant.pending, (state) => {
        state.isMerchantLoading = true
        state.isMerchantSuccess = false
        state.isMerchantError = false
      })
      .addCase(GetMerchant.fulfilled, (state, action) => {
        state.isMerchantLoading = false
        state.isMerchantSuccess = true
        state.isMerchantError = false
        state.merchant = action.payload
      })
      .addCase(GetMerchant.rejected, (state) => {
        state.isMerchantLoading = false
        state.isMerchantSuccess = false
        state.isMerchantError = true
      })
      .addCase(GetHubInfo.pending, (state) => {
        state.isHubLoading = true
        state.isHubSuccess = false
        state.isHubError = false
        state.hubInfo = ''
      })
      .addCase(GetHubInfo.fulfilled, (state, action) => {
        state.isHubLoading = false
        state.isHubSuccess = true
        state.isHubError = false
        state.hubInfo = action.payload
      })
      .addCase(GetHubInfo.rejected, (state) => {
        state.isHubLoading = false
        state.isHubSuccess = false
        state.isHubError = true
        state.hubInfo = ''
      })
      .addCase(GetSession.fulfilled, (state, action) => {
        state.isSessionError = false
        state.isSessionLoading = false
        state.isSessionSuccess = true
        state.session = action.payload
        sessionStorage.setItem('session', JSON.stringify(action.payload))
      })
      .addCase(GetSession.rejected, (state) => {
        state.isSessionError = true
        state.isSessionLoading = false
        state.isSessionSuccess = false
      })
      .addCase(GetSession.pending, (state) => {
        state.isSessionError = false
        state.isSessionLoading = true
        state.isSessionSuccess = false
      })
      .addCase(CreateOrder.fulfilled, (state, action) => {
        state.isOrderLoading = false
        state.isOrderSuccess = true
        state.isOrderError = false
        state.order = action.payload
        if (action.payload.data) {
          state.orderLength = Number(action.payload.data.details.length)
          state.orderGuid = action.payload.data.guid
        }
      })
      .addCase(CreateOrder.rejected, (state) => {
        state.isOrderLoading = false
        state.isOrderSuccess = false
        state.isOrderError = true
      })
      .addCase(GetOrders.pending, (state) => {
        state.isOrderLoading = true
        state.isOrderSuccess = false
        state.isOrderError = false
      })
      .addCase(GetOrders.fulfilled, (state, action) => {
        state.isOrderLoading = false
        state.isOrderSuccess = true
        state.isOrderError = false
        state.order = action.payload
        if (action.payload.data) {
          state.orderLength = Number(action.payload?.data?.details?.length)
          state.orderGuid = action.payload.data.guid
        }
      })
      .addCase(GetOrderInBackground.fulfilled, (state, action) => {
        state.isOrderLoading = false
        state.isOrderSuccess = true
        state.isOrderError = false
        state.order = action.payload
        if (action.payload.data) {
          state.orderLength = Number(action.payload.data.details.length)
          state.orderGuid = action.payload.data.guid
        }
      })
      .addCase(GetOrders.rejected, (state) => {
        state.isOrderLoading = false
        state.isOrderSuccess = false
        state.isOrderError = true
        state.order = null
        state.orderLength = 0
        state.orderGuid = ''
      })
      .addCase(AddToOrder.fulfilled, (state, action) => {
        state.isOrderSuccess = true
        state.isOrderError = false
        state.order = action.payload
        state.orderLength = Number(action.payload.data.details.length)
        state.orderGuid = action.payload.data.guid
      })
      .addCase(GetTableBill.fulfilled, (state, action) => {
        state.isBillsError = false
        state.isBillsSuccess = true
        state.isBillsLoading = false
        state.billInfo = action.payload
        if (action.payload.data) {
          sessionStorage.setItem('Bill', JSON.stringify(action.payload))
        }
      })
      .addCase(GetTableBill.pending, (state) => {
        state.isBillsError = false
        state.isBillsSuccess = false
        state.isBillsLoading = true
        state.billInfo = null
      })
      .addCase(GetTableBill.rejected, (state) => {
        state.isBillsError = true
        state.isBillsSuccess = false
        state.isBillsLoading = false
        state.billInfo = null
      })
      .addCase(AddToOrder.rejected, (state) => {
        state.isOrderSuccess = false
        state.isOrderError = true
        state.order = null
        state.orderLength = 0
      })
      .addCase(UpdateOrder.rejected, (state) => {
        state.isOrderSuccess = false
        state.isOrderError = true
        state.order = null
        state.orderLength = 0
      })
      .addCase(UpdateOrder.fulfilled, (state, action) => {
        state.isOrderSuccess = true
        state.isOrderError = false
        state.order = action.payload
        state.orderLength = Number(action.payload.data.details.length)
        state.orderGuid = action.payload.data.guid
      })
      .addCase(GetReservedBankDetails.fulfilled, (state, action) => {
        state.isBankDetailsError = false
        state.isBankDetailsLoading = false
        state.isBankDetailsSuccess = true
        state.BankDetails = action.payload
        state.accountTimer = action.payload.responseBody.accountDurationSeconds
      })
      .addCase(GetReservedBankDetails.pending, (state) => {
        state.isBankDetailsError = false
        state.isBankDetailsLoading = true
        state.isBankDetailsSuccess = false
      })
      .addCase(GetReservedBankDetails.rejected, (state, action) => {
        state.isBankDetailsError = true
        state.isBankDetailsLoading = false
        state.isBankDetailsSuccess = false
        state.bankMessage = action.payload
        sessionStorage.removeItem('MNFY_DETAILS')
      })
      .addCase(GetTimer.pending, (state) => {
        state.isTimerLoading = true
      })
      .addCase(GetTimer.rejected, (state, action) => {
        state.isTimerLoading = false
        state.bankMessage = action.payload
      })
      .addCase(GetTimer.fulfilled, (state, action) => {
        state.isBankDetailsError = false
        state.isBankDetailsLoading = false
        state.isBankDetailsSuccess = true
        state.BankDetails = action.payload
        state.accountTimer = action.payload.responseBody.accountDurationSeconds
        state.isTimerLoading = false
      })
      .addCase(DeleteOrderItems.fulfilled, (state, action) => {
        state.isOrderSuccess = true
        state.isOrderError = false
        state.order = action.payload
        state.orderLength = Number(action.payload.data.details.length)
        state.orderGuid = action.payload.data.guid
      })
      .addCase(DeleteOrderItems.rejected, (state) => {
        state.isOrderSuccess = false
        state.isOrderError = true
        state.order = null
        state.orderLength = 0
      })
      .addCase(MonnifyLogin.fulfilled, (state, { payload }) => {
        state.monnifySuccess = 'FULFILLED'
        state.monnifyAuthorization = payload
      })
      .addCase(MonnifyLogin.rejected, (state) => {
        state.monnifySuccess = 'REJECTED'
      })
      .addCase(MonnifyLogin.pending, (state) => {
        state.monnifySuccess = 'PENDING'
      })
      .addCase(GetOutsideTransactionStatus.fulfilled, (state, action) => {
        if (action.payload.status === 'Successful') {
          state.transactionStatus.merchantCheck = 'success'
        }
      })
  },
})

export const {
  reset,
  setSplittedBill,
  onOrderApproval,
  realtimeOrderUpdate,
  getBillFromStorage,
  updateTransactionStatus,
  setTipAmount,
  setCategory,
  setParentCategory,
  getMerchantFromStorage,
  getHubFromStorage,
  setSessionSettledStatus,
  setBaseURL,
} = ResourceSlice.actions
export default ResourceSlice.reducer
