import PropTypes from 'prop-types'
import handdrawncircle from '../../images/handdrawn-cricle.png'
const ChargeBreakdown = ({
  billInfo,
  merchant,
  style,
  tipAmount,
  splittedBill,
  myfee,
}) => {
  return (
    <article>
      {billInfo?.data?.merchantAdminDiscount > 0 ? (
        <section className="w-full m-auto my-2 h-5 flex justify-between">
          <h2 className="font-normal capitalize font-Proxima ">discount</h2>
          <h2 className="font-normal capitalize font-Proxima text-right bg-center bg-no-repeat">
            - {'  '}
            {merchant?.data?.currency ? merchant?.data?.currency.symbol : ''}
            {new Intl.NumberFormat('en-US', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }).format(billInfo?.data?.merchantAdminDiscount)}
          </h2>
        </section>
      ) : (
        <></>
      )}

      <section className="w-full m-auto mb-3 flex justify-end">
        {billInfo?.data?.details?.length > 0 && (
          <div className="w-1/4 h-1.2px bg-black"></div>
        )}
      </section>
      <section className=" m-auto min-h-8 flex justify-end">
        <h2 className="font-normal capitalize font-Proxima ">
          {merchant?.data?.currency ? merchant?.data?.currency.symbol : ''}
          {billInfo?.data?.totalAmount.toLocaleString('en-US')}
        </h2>
      </section>
      <section className=" m-auto min-h-1">
        {billInfo?.data?.consumptionTax > 0 && (
          <div className="flex justify-between">
            <h2 className="font-normal capitalize font-Proxima">consumption Tax</h2>
            <h2 className="font-normal capitalize font-Proxima text-right bg-center bg-no-repeat">
              {merchant?.data?.currency ? merchant?.data?.currency.symbol : ''}
              {new Intl.NumberFormat('en-US', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }).format(billInfo.data.consumptionTax) ?? 0.0}
            </h2>
          </div>
        )}
        {(billInfo?.data?.serviceFee > 0 ||
          billInfo?.data?.platformFee > 0 ||
          billInfo?.data?.platformServiceFee > 0) && (
          <div className="flex justify-between">
            <h2 className="font-normal capitalize font-Proxima">service fee</h2>
            <h2 className="font-normal capitalize font-Proxima  text-right bg-center bg-no-repeat ">
              {merchant?.data?.currency ? merchant?.data?.currency.symbol : ''}
              {new Intl.NumberFormat('en-US', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }).format(
                billInfo?.data?.serviceFee +
                  billInfo?.data?.platformFee +
                  billInfo?.data?.platformServiceFee
              ) ?? 0.0}
            </h2>
          </div>
        )}
        {billInfo?.data?.tax > 0 && (
          <div className="flex justify-between">
            <h2 className="font-normal capitalize font-Proxima">vat</h2>
            <h2 className="font-normal capitalize  text-right font-Proxima bg-center bg-no-repeat">
              {merchant?.data?.currency ? merchant?.data?.currency.symbol : ''}
              {new Intl.NumberFormat('en-US', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }).format(billInfo.data.tax)}
            </h2>
          </div>
        )}

        {billInfo.data?.paidAmount > 0 ? (
          <div className="flex justify-between mt-4">
            <h2 className=" capitalize font-Proxima font-normal">Amount Paid</h2>
            <h2 className="font-normal capitalize  text-right font-Proxima bg-center bg-no-repeat">
              {merchant?.data?.currency ? merchant?.data?.currency.symbol : ''}
              {Math.ceil(billInfo?.data?.paidAmount).toLocaleString('en-US')}
            </h2>
          </div>
        ) : (
          <></>
        )}
      </section>
      <section className=" m-auto min-h-1">
        <div className="flex mt-1 justify-between">
          {billInfo?.data?.paidAmount > 0 ? (
            <h2 className="text-2xl  capitalize font-extrabold pt-10 font-Proxima">
              Balance
            </h2>
          ) : (
            <h2 className="text-2xl  capitalize font-extrabold pt-10 font-Proxima">
              Table bill
            </h2>
          )}

          {style !== 'hidden' ? (
            <div className={'h-8 mt-20   grid grid-cols-1 content-end '}>
              <h2 className="text-2xl font-extrabold text-right font-Proxima">
                {merchant?.data?.currency ? merchant?.data?.currency.symbol : ''}
                {(billInfo?.data.outstandingAmount + tipAmount > 0
                  ? Math.ceil(billInfo?.data?.outstandingAmount + tipAmount)
                  : 0
                ).toLocaleString('en-US')}
              </h2>
              <img className={style} src={handdrawncircle} alt={'handrawn circle'} />
            </div>
          ) : (
            <div className={'h-28 pt-10'}>
              <h2 className="text-2xl font-extrabold text-right font-Proxima bg-center bg-no-repeat bg-hidden-dash">
                {merchant?.data?.currency ? merchant?.data?.currency.symbol : ''}
                {Math.ceil(
                  billInfo?.data?.outstandingAmount > 0
                    ? billInfo?.data?.outstandingAmount
                    : 0
                ).toLocaleString('en-US')}
              </h2>
            </div>
          )}
        </div>
        {splittedBill > 0 && (
          <div className="flex -mt-12 justify-between">
            <h2 className="text-2xl capitalize font-extrabold pt-12 font-Proxima">
              your split
            </h2>

            <div className={'h-10 mt-20 grid grid-cols-1 content-end '}>
              <h2 className="text-2xl font-extrabold text-right font-Proxima">
                {merchant?.data?.currency ? merchant?.data?.currency.symbol : ''}
                {myfee.toLocaleString('en-US')}
              </h2>
              <img
                className={'relative -top-10 right-0 -py-6 pl-7 w-36'}
                src={handdrawncircle}
                alt={'handrawn circle'}
              />
            </div>
          </div>
        )}
      </section>
    </article>
  )
}

ChargeBreakdown.propTypes = {
  style: PropTypes.string,
  billInfo: PropTypes.any,
  merchant: PropTypes.any,
  tipAmount: PropTypes.number,
  splittedBill: PropTypes.number,
  myfee: PropTypes.number,
}

export default ChargeBreakdown
