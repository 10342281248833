import PropTypes from 'prop-types'
import React from 'react'

const Popular = ({ IsPopular }) => {
  return (
    <div>
      {IsPopular && (
        <div className="w-full flex mt-1 justify-end">
          <h5 className="w-14 h-4 bg-red-600 text-white text-center text-xs rounded-md">
            *Popular
          </h5>
        </div>
      )}
    </div>
  )
}
Popular.propTypes = {
  IsPopular: PropTypes.bool,
}

export default React.memo(Popular)
