import PropTypes from 'prop-types'
const HeaderImage = ({ merchant }) => {
  return (
    <>
      {merchant?.data?.banner !== '/' ? (
        <>
          <header className="w-full h-41">
            <img src={merchant?.data?.banner} alt="banner" className="h-full  w-full" />
          </header>
          <article className="mask relative -top-20"></article>
        </>
      ) : (
        <header className="home__header"></header>
      )}
    </>
  )
}

HeaderImage.propTypes = {
  merchant: PropTypes.any,
}

export default HeaderImage
