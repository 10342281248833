import React from 'react'
import PropTypes from 'prop-types'

const ItemName = ({ merchant, state }) => {
  return (
    <div className="w-5/6 text-center m-auto mt-2 lg:w-2/4 xl:w-2/4">
      {merchant?.data?.menuFont ? (
        <>
          {' '}
          <h2
            className="uppercase font-extrabold text-2xl my-2"
            style={{
              fontFamily: merchant.data.menuFont
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' '),
            }}
          >
            {state.name}
          </h2>
          <p
            className="font-normal text-center my-2"
            style={{
              fontFamily: merchant.data.menuFont
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' '),
            }}
          >
            {state.description}
          </p>
          <h2
            className="font-extrabold text-xl"
            style={{
              fontFamily: merchant.data.menuFont
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' '),
            }}
          >
            {merchant?.data?.currency ? merchant?.data?.currency?.symbol : ''}
            {state?.price.toLocaleString('en-US')}
          </h2>
        </>
      ) : (
        <>
          <h2 className="font-Catherina CatherinaFont uppercase font-extrabold text-2xl my-2">
            {state.name}
          </h2>
          <p className="font-Proxima font-normal text-center">{state.description}</p>
          <h2 className="font-extrabold text-xl font-Proxima">
            {merchant?.data?.currency ? merchant?.data?.currency?.symbol : ''}
            {state?.price.toLocaleString('en-US')}
          </h2>
        </>
      )}
    </div>
  )
}

ItemName.propTypes = {
  state: PropTypes.any,
  merchant: PropTypes.any,
}

export default React.memo(ItemName)
