import { React, useEffect, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import doubleStroke from '../../images/double.png'
import { useDispatch, useSelector } from 'react-redux'
import { setTipAmount } from '../../redux/resources/resourceAction'
import wingedMoney from '../../images/33-337047_transparent-model-icon-png-give-money-illustration-png.png'

const CustomizedTip = () => {
  const { merchant, tipAmount } = useSelector((state) => state.resource),
    [modal, setModal] = useState(false),
    [customTip, SetCustomTip] = useState(),
    dispatch = useDispatch(),
    changeTipAmount = (tip) => {
      if (tip) {
        dispatch(
          setTipAmount({
            tipAmount: tip,
          })
        )
      } else {
        dispatch(
          setTipAmount({
            tipAmount: 0,
          })
        )
      }
    }

  useEffect(() => {
    if (tipAmount !== customTip) {
      SetCustomTip()
    }
    // eslint-disable-next-line
    }, [tipAmount])

  return (
    <>
      {tipAmount !== 0 && tipAmount === customTip ? (
        <div
          className="h-28 bg-black border-tip border-2 rounded-10px mr-4 shadow-tip border-solid"
          onClick={() => setModal(!modal)}
        >
          <div className="w-full flex justify-end">
            <div className=" w-3 h-3 rounded-full mr-1 mt-1 bg-green-600 "></div>
          </div>
          <div className="w-12 mx-4 m-auto h-12 rounded-full bg-tip ">
            <img
              src={wingedMoney}
              alt="winged money"
              className="w-12 m-auto p-1  rounded-full"
            />
          </div>
          <h2 className=" text-center text-white text-lg font-Proxima">
            {merchant?.data?.currency ? merchant?.data?.currency.symbol : ''}
            {customTip}
          </h2>
          <h5 className="text-center text-white text-sm font-Proxima">custom tip</h5>
        </div>
      ) : (
        <div
          className="h-28 bg-modal border-tip border-2 rounded-10px mr-4 shadow-tip border-solid"
          onClick={() => setModal(!modal)}
        >
          <div className="w-14 mx-3 m-auto h-14 rounded-full bg-modal mt-1">
            <img
              src={wingedMoney}
              alt="winged money"
              className="w-12 m-auto pt-2 rounded-full"
            />
          </div>
          <h2 className=" text-center text-black text-lg font-Proxima">
            {merchant?.data?.currency ? merchant?.data?.currency.symbol : ''}
            {customTip && customTip}
          </h2>
          <h5 className=" text-center text-black text-md font-Proxima">custom tip</h5>
        </div>
      )}

      {modal && (
        <div className="modal">
          <div
            className="overlay__black"
            onClick={() => {
              setModal(!modal)
              SetCustomTip(0)
            }}
          ></div>
          <section className="absolute top-55% bg-white  w-full rounded-t-3xl h-full overflow-auto">
            <div className="w-container m-auto">
              <div className="w-full flex h-10 mb-7 mt-4">
                <h1 className=" font-Catherina text-3xl pt-2 font-extrabold">
                  Tip Waiter?
                </h1>
                <i className="fa-solid fa-money-bill-1-wave text-3xl mx-4 pt-1"></i>
              </div>

              <div className="w-full text-center text-lg font-Proxima">
                <h2 className="font-Proxima">
                  This tip goes directly directly to the
                  <br />
                  waiter serving you.
                </h2>
              </div>

              <div className="modal__input">
                <div>
                  <CurrencyInput
                    prefix={merchant?.data?.currency && merchant?.data?.currency.symbol}
                    className="input__split font-Catherina"
                    placeholder={
                      merchant?.data?.currency && merchant?.data?.currency.symbol + '0.00'
                    }
                    name="bill"
                    autoFocus
                    pattern="[0-9]*"
                    inputMode="numeric"
                    type={'text'}
                    defaultValue={customTip}
                    onValueChange={(value) => {
                      if (value) {
                        SetCustomTip(Number(value))
                      } else {
                        SetCustomTip(0)
                      }
                    }}
                  />
                </div>
                <img src={doubleStroke} alt="double_stroke" />
              </div>
              <div className="flex justify-between w-full h-20 mt-10">
                <button
                  className="bg-white border-2 flex justify-around border-black hover:bg-white tracking-wide text-black text-lg h-12 font-Proxima font-bold w-2/5 rounded-lg capitalize pt-2"
                  onClick={function () {
                    setModal(!modal)
                    SetCustomTip(0)
                    changeTipAmount(0)
                  }}
                >
                  Cancel
                </button>
                <button
                  className="bg-black border-2 flex justify-around pt-2 border-black hover:bg-black tracking-wide text-white text-lg h-12 font-Proxima font-bold w-2/5 rounded-lg capitalize"
                  onClick={() => {
                    setModal(!modal)
                    changeTipAmount(customTip)
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  )
}

export default CustomizedTip
