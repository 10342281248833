import React from 'react'
import Lottie from 'lottie-react'
import ErrorGIF from '../../images/76706-404-error-page.json'
const NotFound = () => {
  return (
    <main className="h-screen bg-contain bg-restaurant-pattern">
      <article className="w-80 m-auto pt-20">
        <h3 className="text-black text-4xl font-extrabold capitalize text-center font-Catherina pb-9">
          oops!!!
        </h3>
        <Lottie
          animationData={ErrorGIF}
          autoPlay
          loop
          mode
          style={{ width: '100%', height: '100px' }}
        ></Lottie>
        <p className="text-center text-xl my-9 font-Proxima capitalize">
          Page Doesn&apos;t Exist
        </p>
      </article>
    </main>
  )
}
export default NotFound
