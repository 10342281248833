import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import {
  AddToOrder,
  CreateOrder,
  UpdateOrder,
} from '../../redux/resources/resourceAction'
import PropTypes from 'prop-types'
import ButtonArea from './Button'
import IsCarted from './IsCarted'
import Popular from './Popular'
import MenuImage from './MenuImage'
import ItemName from './ItemName'

const SingleItem = ({ state, order }) => {
  const [selected, isSelected] = useState(false),
    dispatch = useDispatch(),
    params = useParams(),
    { merchant, hubInfo, session } = useSelector((state) => state.resource),
    thisItemIsSelected = order?.data
      ? order?.data?.details.find((data) => data?.productGuid === state?.guid)
      : false,
    navigate = useNavigate()
  function selectItem() {
    isSelected(true)
    if (order?.data !== null) {
      if (thisItemIsSelected) {
        dispatch(
          UpdateOrder({
            Guid: params.merchantGuid,
            orderGuid: order?.data?.guid,
            quantity: thisItemIsSelected?.quantity + 1,
            productGuid: state?.guid,
            orderDetailsGuid: thisItemIsSelected?.guid,
          })
        )
      } else {
        dispatch(
          AddToOrder({
            Guid: params.merchantGuid,
            orderGuid: order?.data?.guid,
            quantity: 1,
            productGuid: state?.guid,
          })
        )
      }
    } else {
      if (params.sessionGuid && session?.data === null) {
        navigate('/')
        sessionStorage.clear()
      } else {
        dispatch(
          CreateOrder({
            hubGuid: params.hubGuid,
            Guid: params.merchantGuid,
            quantity: 1,
            productGuid: state?.guid,
          })
        )
      }
    }
    setTimeout(() => {
      isSelected((prevState) => !prevState)
    }, 1500)
  }
  return (
    <article className="dish">
      <Popular IsPopular={state?.isPopular} />
      <MenuImage image={state.image} slug={state.slug} />
      <ItemName merchant={merchant} state={state} />
      <ButtonArea selectItem={selectItem} selected={selected} />
      <IsCarted thisItemIsSelected={thisItemIsSelected} hubInfo={hubInfo} />
      <div className="flex w-container m-auto mt-3 justify-center">
        <div className="w-16 h-1px mt-1  rounded-xl bg-grey"></div>
        <div className="w-2 mx-4 h-2 rounded-xl bg-grey"></div>
        <div className="w-16 h-1px mt-1 rounded-xl bg-grey"></div>
      </div>
    </article>
  )
}

SingleItem.propTypes = {
  state: PropTypes.any,
  order: PropTypes.any,
}

export default React.memo(SingleItem)
