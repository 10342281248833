import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetTableBill, getBillFromStorage } from '../redux/resources/resourceAction'
import { useParams } from 'react-router'
import PropTypes from 'prop-types'

const useGetTableBill = ({ billPage }) => {
  const dispatch = useDispatch(),
    params = useParams(),
    { session } = useSelector((state) => state.resource)

  useEffect(() => {
    if (session?.data && session?.succeeded) {
      dispatch(
        GetTableBill({
          merchantGuid: params.merchantGuid,
          sessionGuid: params.sessionGuid ?? session?.data?.session?.guid,
        })
      )
    } else {
      if (billPage) {
        const BillFromStorage = JSON.parse(sessionStorage.getItem('Bill'))
        if (BillFromStorage) {
          dispatch(
            getBillFromStorage({
              bill: { ...BillFromStorage, fromLocalStorage: true },
            })
          )
        }
      }
    }
  }, [session])
}

useGetTableBill.propTypes = {
  billPage: PropTypes.any,
}

export default useGetTableBill
