import { React, useEffect } from 'react'
import { useNavigate } from 'react-router'
import success from '../../images/79952-successful.gif'
import PropTypes from 'prop-types'

const SuccessPopUP = ({ url }) => {
  const navigate = useNavigate()

  useEffect(() => {
    const timeOut = setTimeout(() => {
      navigate(url)
    }, 2000)

    return () => clearTimeout(timeOut)
  }, [navigate, url])

  return (
    <>
      <header className="w-full h-20"></header>
      <main className="w-full h-60">
        <section className="w-full">
          <img src={success} alt="success  :-)" className="" />
        </section>
      </main>
    </>
  )
}

SuccessPopUP.propTypes = {
  url: PropTypes.string,
}

export default SuccessPopUP
